import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {


  user;
  constructor(private http:HttpClient,private auth:AuthenticationService) { 
//    this.auth.currentUser.subscribe(currentUser=>this.user=currentUser)
  }


  // addTowishlist(id){
  //   const formData: FormData = new FormData();
  //   formData.append('product_id',id)
  //   return this.http.post(`${environment.endPoint}/wishlist/add`,formData)
  // }
  
  // deleteWishlistitem(id){
  //   const formData: FormData = new FormData();
  //   formData.append('product_id',id)
  //   return this.http.post(`${environment.endPoint}/wishlist/delete`,formData)
  // }

 
//////////////////New/////////////////

  toggleWishList(f){
    const formData: FormData = new FormData();
    for (const [key, value] of Object.entries(f)) {
      if( value!="null") {
        formData.append(key,`${value}`)
      }}
    // if(f.session_id!='null'){
    //  console.log( "wishlist === notttttttt nullllllll")
    //   formData.append('product_id',f.product_id)
    //   formData.append('session_id',f.session_id)
    // }
    // else {
    //   formData.append('product_id',f.product_id)
    //   }
   
    return this.http.post(`${environment.urlPoint}/wishlist/toggle`,formData)
    
  }


  allWishlist(form){
    const formData: FormData = new FormData();
   // let session_id = localStorage.getItem('session_id')
      for (const [key, value] of Object.entries(form)) {
         if(value!='null' ) {
            formData.append(key,`${value}`)
           }
           else {
            formData.append(key,'null')
           }
       }
      return this.http.post(`${environment.urlPoint}/wishlist/show`,formData)
      }

}
