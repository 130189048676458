<ngx-spinner  bdColor = "rgba(0, 0, 0, 0.7)"></ngx-spinner>  
   <!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "line-spin-clockwise-fade-rotating" [fullScreen] = "true"> </ngx-spinner> -->
   <!--ball-pulse-->
 
 <a (click)="openInstantOrder()"> <img src="assets/images/home/instant.svg" height="auto" width="100%"> </a> 
 <app-cart *ngIf="cartOpen"></app-cart> 
 <router-outlet></router-outlet>
 
 <!-- <app-footer></app-footer>   -->
 
 <!-- <div *ngIf="!mobile"> 
    <router-outlet></router-outlet>
    <app-footer></app-footer>  
 </div>

 <div *ngIf="mobile"> 
    <p> custom mobile nav</p>
    <router-outlet></router-outlet>
 </div> -->
