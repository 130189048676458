import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressService } from 'src/app/services/address.service';
import { AppService } from 'src/app/services/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit {
  form:FormGroup;
  city:number ; 
  country:number ;
  cities:any=[];
  countries:any=[];
  submitted:boolean=false ;
  constructor(private service:AppService , private address:AddressService, private formbuilder:FormBuilder , public dialogRef: MatDialogRef<EditAddressComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getAllCities() ;
    this.getAllCountries();

    this.form = this.formbuilder.group({
      first_name:[this.data.first_name,Validators.required],
      last_name:[this.data.last_name,Validators.required],
      phone:[this.data.phone,[Validators.pattern("[0-9]{9}"),Validators.required]],  //   phone:['',[Validators.pattern("[0-9 ]{9}"),Validators.required]],
      address:[this.data.address,Validators.required],
      city_id:[this.data.city_id,Validators.required],
      country_id:[this.data.country_id,Validators.required],
    })

  }

  getAllCities(){
    this.service.getCities().subscribe(res=>{
      this.cities=res['data'];
    //  console.log("All Cities" , this.cities)
    })
  }

  getAllCountries(){
    this.service.getCountries().subscribe(res=>{
      this.countries=res['data'];
  //    console.log("All Countries" , this.countries)
    })
  }

  get f() {return this.form.controls}
  submit() {
     this.submitted=true ;
     if (this.form.invalid) { return ; }
    let newForm = {
      ...this.form.value ,
      address_id:this.data.id,
      
    }
  //  console.log("form", newForm)
     this.service.editAddress(newForm).subscribe((response:any)=> {
    //   console.log("Edit Address" , response)
       if(response.status==true){
        Swal.fire(
          `Success` , 
          `${response.message}` , 
          `success`
        ) 
         this.dialogRef.close();
      
       }
       else {
        Swal.fire(
          `Fail` , 
          `${response.errors[0]}` , 
          `error`
        )
       }
     })
  }
}
