 <div class="container">  
     <div class="row justify-content-center">  
            <div class="col-12 popup"> 
                <div class="popup"> 
                   <button class="btn" mat-button [mat-dialog-close]="true" > <span class="text-secondary"> x </span> </button>
                </div>
                  
                <h5 class="text-center"  *ngIf="data.title=='Write Review'"> {{'placeholders.writeReview' | translate }}</h5> 
                <h5 class="text-center"  *ngIf="data.title!='Write Review'"> {{'main.orderRate' | translate }}</h5> 
                <div class="text-center" *ngIf="data.from=='orders'"> 
                    <!-- <p *ngIf="show_order_rating" class="col-12 mt-4 mb-2 rate text-center"  (click)="show_order_rating=false">
                       
                      <svg *ngFor="let i of [].constructor(5)" style="color: #ccc;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ms-2 bi bi-star-fill" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                      </svg>
                     </p> -->
                    <ngx-stars  class="col-12 mt-4 mb-2" (ratingOutput)="orderWriteReview($event)" [wholeStars]="true" [readonly]="false" [size]="5" [initialStars]="onRatingSetValue" [color]="'rgb(255 193 7)'"></ngx-stars>
                    <textarea class="col-12" rows="5" #notes placeholder="{{'placeholders.writeComment' | translate }} "></textarea> 
                    <br> 
                    <button type="submit" class="btn btn-success mt-3 px-5" (click)="setRateOrder()"> {{'main.send' | translate }}  </button> 
                 </div>

                 <div class="text-center" *ngIf="data.from=='review'"> 
                    <!-- <p *ngIf="show_star_rating" class="col-12 mt-4 mb-2 rate text-center"  (click)="onWriteValue=onWriteValue+1 ; show_star_rating=false">
                       <svg *ngFor="let i of [].constructor(5)" style="color: #ccc;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ms-2 bi bi-star-fill" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                      </svg>
                     </p> -->
                    <ngx-stars class="col-12 mt-4 mb-2" (ratingOutput)="productWriteReview($event)" [wholeStars]="true" [readonly]="false" [size]="5" [initialStars]="onRatingSetValue" [color]="'rgb(255 193 7)'"></ngx-stars>
                    <textarea class="col-12" rows="5" #notes placeholder="{{'placeholders.writeComment' | translate }} "></textarea> 
                    <br> 
                    <button type="submit" class="btn btn-success mt-3 px-5" (click)="setProductOrder()"> {{'main.send' | translate }}  </button> 
                 </div>
          </div>
    </div> 
</div>
 <!-- cols="50" rows="5" -->