import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/orders.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  // show_star_rating:boolean = true ;
  // show_order_rating:boolean = true ;
  onRatingSetValue = 0;
 
  onWriteValue = 0
@ViewChild ('notes') notes :ElementRef ;
  constructor( private orders:OrdersService ,public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
   
  ngOnInit(): void {
   console.log("Dialog" ,this.data)
  //  this.show_star_rating = true ;
  //  this.show_order_rating=true;
  }

  orderWriteReview(onRatingSetValue){
    this.onRatingSetValue = onRatingSetValue
   // console.log("onRatingSet",this.onRatingSetValue)
  }
 
  setRateOrder(){
    let form ={
      order_id : this.data.another_data ,
      rating :this.onRatingSetValue ,
      review : this.notes.nativeElement.value
    }
    //console.log("Rate Form ",form)
    this.orders.rateOrder(form).subscribe((res:any)=>{

      if(res.status==true) {
      console.log("res rate" , res)
        setTimeout(() =>{
          this.dialogRef.close();
            },1000);
           Swal.fire(
             'Successfully !',
            `${res.message}`,
             'success'
              )
      }

      else {
          Swal.fire(
             'Fail !',
            `${res.errors[0]}`,
             'error'
              )
           setTimeout(() =>{
             this.dialogRef.close();
            },1000);
      }
        
    })
    
  }

  productWriteReview(onWriteReviewValue){
    this.onWriteValue = onWriteReviewValue
    console.log("onWriteValue",this.onWriteValue)
  }

  setProductOrder() {
   // console.log("onWriteValue2",this.onWriteValue)
    let form ={
      product_id : this.data.another_data ,
      rating :this.onWriteValue ,
      review : this.notes.nativeElement.value
    }
   // console.log("Review Rate Form ",form)
    this.orders.rateProduct(form).subscribe((res:any)=>{

      if(res.status==true) {
        console.log("res rate" , res)
        setTimeout(() =>{
          this.dialogRef.close();
            },1000);
           Swal.fire(
             'Successfully !',
            `${res.message}`,
             'success'
              )
      }

      else {
          Swal.fire(
             'Fail !',
            `${res.errors[0]}`,
             'error'
              )
           setTimeout(() =>{
             this.dialogRef.close();
            },1000);
      }
        
    })
    
  }
}
