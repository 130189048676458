<div class="scrollable"> 
    <div class="scroll d-flex">
        <div class="item" [routerLink]="['/']"> 
            <img src="assets/images/scroll-nav/home.svg" class="mb-2"> 
            <p class="text-muted">{{ 'header.home' | translate }} </p>
        </div>
        <div class="item" [routerLink]="['/categories']"> 
            <img src="assets/images/scroll-nav/categories.svg" class="mb-2"> 
            <p class="text-muted">{{ 'main.mainCategories' | translate }}</p>
        </div>
        <div class="item" (click)="openInstantOrder()"> 
            <img src="assets/images/scroll-nav/instant.svg" class="mb-2"> 
            <p class="text-muted">{{ 'instantOrder.instantOrders' | translate }}</p>
        </div>
        <div class="item" [routerLink]="['/brands']"> 
            <img src="assets/images/scroll-nav/brands.svg" class="mb-2"> 
            <p class="text-muted"> {{ 'header.brands' | translate }} </p>
        </div>
        <div class="item text-left" [routerLink]="['/orders/shopping-cart']"> 
            <img src="assets/images/scroll-nav/cart.svg" class="mb-2"> 
            <p class="text-muted">{{ 'cart.cart' | translate }} </p>
        </div>

    </div>  
</div>
