import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  user;
  constructor(private http:HttpClient,private auth:AuthenticationService) { 
    this.auth.currentUser.subscribe(currentUser=>this.user=currentUser)
  }


  ///////////////////////////////////shrouq////////////////////////////////////
defaultAddress(address_id){
  return this.http.get(`${environment.urlPoint}/user/address/set/default?address_id=${address_id}`)
}

makeOrder(form){
  const formData:FormData = new FormData()
  for (const [key, value] of Object.entries(form)) {
    if(value!=null) {
      formData.append(key,`${value}`)
    }}
  return this.http.post(`${environment.urlPoint}/user/order/create`,formData)
}

showCouponDetails(coupon_code){
  return this.http.get(`${environment.urlPoint}/coupon/show?coupon_code=${coupon_code}`)
}

getOrderByGroup(group_id ,page){
  return this.http.get(`${environment.urlPoint}/user/orders/show?group_id=${group_id}&page=${page}`) 
}

getOrderByStatus(status_id,page){
  return this.http.get(`${environment.urlPoint}/user/orders/show?status_id=${status_id}&page=${page}`) 
}

cancelOrder(order_id){
  return this.http.get(`${environment.urlPoint}/user/order/cancel?order_id=${order_id}`) 
}

rateOrder(form){
  return this.http.post(`${environment.urlPoint}/user/order/rating`,form)
}
 
rateProduct(form){
  return this.http.post(`${environment.urlPoint}/user/product/rating/create`,form)
}

getInstantOrders(page){
  return this.http.get(`${environment.urlPoint}/user/instant-orders/all?page=${page}`) 
}
 
///////////////////////////////////shrouq////////////////////////////////////


  ///////////////////////////////////Sawy////////////////////////////////////
  // allusersOrders(){
  //   const formData:FormData = new FormData();
  //   // formData.append('status',status)
  //   formData.append('token',this.user.token)
  //   return this.http.post(`${environment.endPoint}/orders`,formData)
  // }

  // addOrder(address_id,promo_id){
  //   const formData:FormData = new FormData();
  //   formData.append('address_id',address_id)
  //   if(promo_id)
  //   formData.append('promo_id',promo_id)
    
  //   return this.http.post(`${environment.endPoint}/orders/add`,formData)
  // }
  
  // checkpromo(promocode){
  //   const formData:FormData = new FormData();
  //   formData.append('promocode',promocode)
  //   return this.http.post(`${environment.endPoint}/checkpromo`,formData)
  // }
  
  ///////////////////////////////////Sawy////////////////////////////////////
}
