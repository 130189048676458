<footer  *ngIf="footerobj" class="pt-5">  
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-12">
          
            <!-- animation: mymove 5s infinite;
            }
            @keyframes mymove {
            from {top: 0px;}
            to {top: 200px;}
            } -->
            <!-- <div class="hero__icons">
                <img decoding="async" class="icon1" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/f_01-1.png" alt="">
                <img decoding="async" class="icon2" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/f_02-1.png" alt="">
                <img decoding="async" class="icon3" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/h_08.png" alt="">
                <img decoding="async" class="icon4" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/h_05.png" alt="">
                <img decoding="async" class="icon5" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/h_09.png" alt="">
                <img decoding="async" class="icon6" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/h_10.png" alt="">
                <img decoding="async" class="icon7" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/h_11.png" alt="">
                <img decoding="async" class="icon8" src="https://themexriver.com/appilo-theme/saas-app/wp-content/uploads/sites/63/2022/06/h_12.png" alt="">
            </div>   -->
                <div class="row footer">
                    <div class="col-lg-4 col-md-12 text-left col-12 " id="right-col">
                        <img class="p-3 img-fluid" src="assets/images/header/updated/o_getlogowhite.png" alt="logo"loading= "lazy" height="150px" width="150px">
                        <p class="p-3">
                        {{footerobj?.description}} v1.45
                        </p>
                        <a *ngFor="let social of social_links"  class="mx-2" [href]="social.link">
                          <!-- <img  class="social img-fluid" [lazyLoad]="social.logoImagePath"> -->
                          <img  class="social img-fluid" [src]="social.logoImagePath" alt="icon">
                        </a>
                    </div>

                    <div class="col-lg-8 col-md-12 right mt-3 col-12 " id="left-col">
                        <div class="row footer_col">
                            <div class="col-lg-3 col-md-3 my-1 ">
                                <h6 class="fw-bold my-2" (click)="replacement=!replacement">
                                    {{'main.mainCategories'|translate}}
                                </h6>
                                <ul class="list-unstyled p-0"  [ngClass]="{'show': replacement== false , 'hide':replacement==true}">
                                    <li *ngFor="let cat of categories"> <a (click)="goProducts(cat , 'category')"> {{cat.name}}</a> </li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-3 my-1">
                                <h6 class="fw-bold my-2" (click)="performance=!performance">
                                    {{'main.carsBrands'|translate}}
                                </h6>
                                <ul class="list-unstyled p-0" [ngClass]="{'show': performance== false , 'hide':performance==true}" >
                                    <li *ngFor="let brand of makers"> <a (click)="goProducts(brand ,'brand')">{{brand.name}} </a> </li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-3 my-1 models" >
                                <h6 class="fw-bold my-2" (click)="accessories=!accessories">
                                    {{'main.models'|translate}}
                                </h6>
                                <ul class="list-unstyled p-0" [ngClass]="{'show': accessories== false , 'hide':accessories==true}" >
                                    <li *ngFor="let model of models"> <a (click)="goProducts(model ,'model')">{{model.name}}</a></li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-3 my-1 about" >
                                <h6 class="fw-bold my-2" (click)="about=!about">
                                    {{'footer.policies'|translate}}
                                </h6>
                                <ul class="list-unstyled p-0" [ngClass]="{'show': about== false , 'hide':about==true}" >
                                    <li> <a [routerLink]="['/privacy-policy']">  {{'footer.scarPolicy'|translate}} </a> </li>
                                    <li> <a>  {{'footer.faq'|translate}} </a> </li>
                                    <li> <a [routerLink]="['/return-policy']">  {{'footer.scarWarrantyPolicy'|translate}} </a> </li>
                                    <li> <a [routerLink]="['/shipping-policy']">  {{'footer.scarShippingPolicy'|translate}} </a> </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div *ngIf="footerobj" class="container-fluid">
    <div class="bottom-footer row justify-content-center mx-0 mb-5">
        <div class="col-lg-11 col-md-12 col-12 text-center py-4">
                <div class="row justify-content-center m-0" style="border-bottom:1px solid #B1B1B1;   margin-top: 20px;  padding-bottom: 20px;">  
                 <div class="col-lg-4 col-md-4 col-sm-12 foot m-auto p-0">
                        <img src="assets/images/footer/earthWebp.webp" class="image-fluid" alt="icon">
                        &nbsp;  <span class="pl-3"> {{ 'footer.ksa' | translate }}</span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 foot m-auto p-0">
                        <img src="assets/images/footer/msgWebp.webp" class="image-fluid" alt="icon">
                        &nbsp;  <span class="pl-3" >{{footerobj?.email}}</span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 foot m-auto p-0">
                        <img src="assets/images/footer/phoneWebp.webp" class="image-fluid" alt="icon">
                    &nbsp; <span class="pl-3">{{footerobj?.phone}}</span>
                    </div>
                </div>
                <div class="row justify-content-center copyRights bottom m-0">
                    <!-- <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <p class="m-auto"> {{'footer.copyrightMsg' | translate }}</p>
                    </div>

                    <div class="col-lg-8 col-md-12 col-sm-12 p-0  mt-3">
                        <div class="row">
                            <span class="col-xl-3 col-lg-3 col-md-3 col-xs-5 col-6 text-left"> <a [routerLink]="['/privacy-policy']">  {{'footer.scarPolicy'|translate}} </a> </span>
                            <span class="col-xl-3 col-lg-3 col-md-3 col-xs-2 col-6 text-left"> <a>  {{'footer.faq'|translate}} </a></span>
                            <span class="col-xl-3 col-lg-3 col-md-3 col-xs-5 col-6 text-left"> <a [routerLink]="['/return-policy']">  {{'footer.scarWarrantyPolicy'|translate}} </a> </span>
                            <span class="col-xl-3 col-lg-3 col-md-3 col-xs-5 col-6 text-left"> <a [routerLink]="['/shipping-policy']">  {{'footer.scarShippingPolicy'|translate}} </a></span>
                        </div>
                    </div> -->
                     <p class="col-md-6 col-12"> <span  class=""> {{'footer.copyrightMsg'|translate}} </span></p> 
                     <p class="col-md-6 col-12"> <span class=""> {{'footer.designrightMsg'|translate}} <a href="https://pomac.info" >{{'footer.pomac' | translate }}</a></span> </p>  
                </div>
            </div>

    </div>
</div>
