 <!-- <section>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
             <ul class="search_list list-unstyled col-2 align-self-center justify-content-between pe-0 m-0">
              <li class="  nav-item  icon col-auto mx-3" *ngIf="hide_search_icon_in_filter_page">
                <a (click)="openModal(template)">
                  <img src="assets/images/header/head_search.svg" height="16px" width="16px">
                </a>
              </li>
              <li class="nav-item  icon  align-self-center col-auto">
                <a (click)="showCart()" id="showCart" href="javascript:void(0)">

                  <img src="assets/images/header/head_cart.svg" height="20px" width="20px">
               </a>
            </li>
             </ul>
             <a class="navbar-brand" href=" "><img src="assets/images/header/logoWebp.webp" height="auto" width="75%" class="img-fluid" alt="" srcset=""> </a>

              <button class="navbar-toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarText" style="justify-content:end">
                <ul class="navbar-nav m-auto text-center pb-1 px-0" *ngIf="!auth">
                    <li class="nav-item" (click)="closeNav()">
                      <a class="nav-link" [routerLink]="['/']" [routerLinkActive]="'active-nav'" [routerLinkActiveOptions]={exact:true}>{{ 'header.home' | translate }} </a>
                    </li>
                    <li class="nav-item" (click)="closeNav()">
                      <a class="nav-link" [routerLink]="['/categories']" [routerLinkActive]="'active-nav'"> {{ 'main.mainCategories' | translate }}</a>
                    </li>
                    <li class="nav-item" (click)="closeNav()">
                      <a class="nav-link" [routerLink]="['/brands']" [routerLinkActive]="'active-nav'"> {{ 'header.brands' | translate }}</a>
                    </li>
                    <li class="nav-item" (click)="closeNav()">
                      <a class="nav-link" [routerLink]="['/about-us']" [routerLinkActive]="'active-nav'"> {{ 'header.aboutus' | translate }}</a>
                    </li>
                    <li class="nav-item" (click)="closeNav()">
                      <a class="nav-link" [routerLink]="['/contact-us']" [routerLinkActive]="'active-nav'"> {{ 'header.contactUs' | translate }}</a>
                    </li>
                </ul>

                <ul class="navbar-nav mb-lg-0 navbar-text me-2 px-0" style=" min-height:48px ;">
                  <div class="d-flex align-self-center" *ngIf="!auth">
                      <li class="nav-item icon mt-3 mx-1 align-self-center search"  *ngIf="show_search&& hide_search_icon_in_filter_page">
                        <input class="mt-1  form-control align-self-center" style="padding:8px ; border-radius: 27px; display: inline; border:none ; background-color:#E8ECEF ;" type="text" placeholder=" {{ 'top-bar.searchParts' | translate }}" aria-label="Search" #search (change)="setSearchValue($event)">
                          <a (click)="searchProducts(1)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                          </a>
                      </li>

                      <li class="search-field nav-item  icon mx-1 align-self-center " *ngIf="hide_search_icon_in_filter_page">
                        <a (click)="show_search=true ; showsearch=false ; showheart=true ;showcart =true; showsetting=true"  *ngIf="!show_search  && showsearch==true">
                          <svg xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                            <g id="Group_69049" data-name="Group 69049" transform="translate(-1295 -30)">
                              <g id="Group_12543" data-name="Group 12543" transform="translate(29)">
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1266 30)" fill="#062c4c" opacity="0.09"/>
                                <g id="Iconly_Light_Search" data-name="Iconly/Light/Search" transform="translate(1280 43)">
                                  <g id="Search" transform="translate(-0.778 -0.778)">
                                    <circle id="Ellipse_739" cx="8.989" cy="8.989" r="8.989" transform="translate(0.778 0.778)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Line_181" d="M0,0,3.524,3.515" transform="translate(16.018 16.485)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>

                          <svg *ngIf="!showsearch " xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                            <g id="Group_69049" data-name="Group 69049" transform="translate(-1295 -30)">
                              <g id="Group_12543" data-name="Group 12543" transform="translate(29)">
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1266 30)" fill="#062c4c" />
                                <g id="Iconly_Light_Search" data-name="Iconly/Light/Search" transform="translate(1280 43)">
                                  <g id="Search" transform="translate(-0.778 -0.778)">
                                    <circle id="Ellipse_739" cx="8.989" cy="8.989" r="8.989" transform="translate(0.778 0.778)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Line_181" d="M0,0,3.524,3.515" transform="translate(16.018 16.485)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </a>
                      </li>

                      <li class="nav-item  icon mx-1 align-self-center">

                          <a [routerLink]="['/setting/wishlist']" (click)="showheart=false ;showcart =true; showsetting=true ;showsearch=true ">

                            <svg *ngIf="showheart" xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 46 46">
                              <g id="Group_69043" data-name="Group 69043" transform="translate(-1365 -30)">
                                <g id="Group_12422" data-name="Group 12422" transform="translate(13)">
                                  <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1352 30)" fill="#062c4c" opacity="0.09"/>
                                  <g id="Iconly_Light_Heart" data-name="Iconly/Light/Heart" transform="translate(1363 42)">
                                    <g id="Heart">
                                      <path id="Path_33961" d="M11.834,22.813a44.517,44.517,0,0,1-7.694-5.991A15.321,15.321,0,0,1,.477,10.983C-.9,6.709.708,1.816,5.2.367a7.639,7.639,0,0,1,6.932,1.168h0A7.65,7.65,0,0,1,19.066.367C23.56,1.816,25.177,6.709,23.8,10.983a15.321,15.321,0,0,1-3.662,5.839,44.517,44.517,0,0,1-7.694,5.991l-.3.187Z" transform="translate(0 0)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Path_33964" d="M0,0A3.554,3.554,0,0,1,2.449,3.095" transform="translate(16.917 5.179)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>


                            <svg *ngIf="!showheart " xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 46 46">
                              <g id="Group_69045" data-name="Group 69045" transform="translate(-1365 -30)">
                                <g id="Group_12422" data-name="Group 12422" transform="translate(13)">
                                  <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1352 30)" fill="#062c4c"/>
                                  <g id="Iconly_Light_Heart" data-name="Iconly/Light/Heart" transform="translate(1363 42)">
                                    <g id="Heart">
                                      <path id="Path_33961" d="M11.834,22.813a44.517,44.517,0,0,1-7.694-5.991A15.321,15.321,0,0,1,.477,10.983C-.9,6.709.708,1.816,5.2.367a7.639,7.639,0,0,1,6.932,1.168h0A7.65,7.65,0,0,1,19.066.367C23.56,1.816,25.177,6.709,23.8,10.983a15.321,15.321,0,0,1-3.662,5.839,44.517,44.517,0,0,1-7.694,5.991l-.3.187Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Path_33964" d="M0,0A3.554,3.554,0,0,1,2.449,3.095" transform="translate(16.917 5.179)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                      </li>


                      <li class="nav-item search-field icon mx-1 align-self-center">
                          <a (click)="showCart() ; showcart=false; showheart=true ; showsetting=true  ;showsearch=true " id="showCart" href="javascript:void(0)">
                            <svg *ngIf="showcart  " xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                              <g id="Group_69047" data-name="Group 69047" transform="translate(-1435 -30)">
                                <g id="Group_12421" data-name="Group 12421" transform="translate(13)">
                                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" opacity="0.09"/>
                                  <g id="Iconly_Light_Buy" data-name="Iconly/Light/Buy" transform="translate(1433 42)">
                                    <g id="Buy">
                                      <path id="Stroke_1" data-name="Stroke 1" d="M.975,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.975,0Z" transform="translate(4.99 21.057)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Stroke_3" data-name="Stroke 3" d="M.977,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.977,0Z" transform="translate(19.355 21.057)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Stroke_5" data-name="Stroke 5" d="M0,0,2.656.457l1.23,14.58a2.3,2.3,0,0,0,2.294,2.1H20.111a2.3,2.3,0,0,0,2.279-1.965L23.6,6.839a1.706,1.706,0,0,0-1.694-1.948H3.082" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Stroke_7" data-name="Stroke 7" d="M0,.5H3.54" transform="translate(14.524 9.088)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>

                            <svg *ngIf="!showcart " xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 46 46">
                              <g id="Group_69047" data-name="Group 69047" transform="translate(-1435 -30)">
                                <g id="Group_12421" data-name="Group 12421" transform="translate(13)">
                                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" />
                                  <g id="Iconly_Light_Buy" data-name="Iconly/Light/Buy" transform="translate(1433 42)">
                                    <g id="Buy">
                                      <path id="Stroke_1" data-name="Stroke 1" d="M.975,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.975,0Z" transform="translate(4.99 21.057)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Stroke_3" data-name="Stroke 3" d="M.977,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.977,0Z" transform="translate(19.355 21.057)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Stroke_5" data-name="Stroke 5" d="M0,0,2.656.457l1.23,14.58a2.3,2.3,0,0,0,2.294,2.1H20.111a2.3,2.3,0,0,0,2.279-1.965L23.6,6.839a1.706,1.706,0,0,0-1.694-1.948H3.082" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <path id="Stroke_7" data-name="Stroke 7" d="M0,.5H3.54" transform="translate(14.524 9.088)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>

                          </a>
                      </li>


                      <li class="nav-item  icon dropdown  mx-1 align-self-center" style="position:relative ; top:0px ; left:-1px"  *ngIf="user">
                          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" (click)="showsetting=false ;showheart =true;showcart =true ;showsearch=true ">

                            <svg  *ngIf="showsetting " xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                              <g id="Group_69042" data-name="Group 69042" transform="translate(-1505 -30)">
                                <g id="Group_12625" data-name="Group 12625" transform="translate(83)">
                                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" opacity="0.09"/>
                                  <g id="Iconly_Light_Setting" data-name="Iconly/Light/Setting" transform="translate(1436.106 43.389)">
                                    <g id="Setting" transform="translate(-0.779 -0.778)">
                                      <path id="Path_33946" d="M17.528,5.346l-.622-1.08a1.913,1.913,0,0,0-2.609-.7h0a1.9,1.9,0,0,1-2.609-.677,1.831,1.831,0,0,1-.256-.915h0A1.913,1.913,0,0,0,9.519,0H8.265a1.9,1.9,0,0,0-1.9,1.913h0A1.913,1.913,0,0,1,4.448,3.8a1.831,1.831,0,0,1-.915-.256h0a1.913,1.913,0,0,0-2.609.7l-.668,1.1a1.913,1.913,0,0,0,.7,2.609h0a1.913,1.913,0,0,1,.957,1.657,1.913,1.913,0,0,1-.957,1.657h0a1.9,1.9,0,0,0-.7,2.6h0l.632,1.089A1.913,1.913,0,0,0,3.5,15.7h0a1.895,1.895,0,0,1,2.6.7,1.831,1.831,0,0,1,.256.915h0a1.913,1.913,0,0,0,1.913,1.913H9.519a1.913,1.913,0,0,0,1.913-1.9h0a1.9,1.9,0,0,1,1.913-1.913,1.95,1.95,0,0,1,.915.256h0a1.913,1.913,0,0,0,2.609-.7h0l.659-1.1a1.9,1.9,0,0,0-.7-2.609h0a1.9,1.9,0,0,1-.7-2.609,1.876,1.876,0,0,1,.7-.7h0a1.913,1.913,0,0,0,.7-2.6h0Z" transform="translate(0.779 0.778)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <circle id="Ellipse_737" cx="2.636" cy="2.636" r="2.636" transform="translate(7.039 7.753)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>

                            <svg  *ngIf="!showsetting " xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                              <g id="Group_69042" data-name="Group 69042" transform="translate(-1505 -30)">
                                <g id="Group_12625" data-name="Group 12625" transform="translate(83)">
                                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" />
                                  <g id="Iconly_Light_Setting" data-name="Iconly/Light/Setting" transform="translate(1436.106 43.389)">
                                    <g id="Setting" transform="translate(-0.779 -0.778)">
                                      <path id="Path_33946" d="M17.528,5.346l-.622-1.08a1.913,1.913,0,0,0-2.609-.7h0a1.9,1.9,0,0,1-2.609-.677,1.831,1.831,0,0,1-.256-.915h0A1.913,1.913,0,0,0,9.519,0H8.265a1.9,1.9,0,0,0-1.9,1.913h0A1.913,1.913,0,0,1,4.448,3.8a1.831,1.831,0,0,1-.915-.256h0a1.913,1.913,0,0,0-2.609.7l-.668,1.1a1.913,1.913,0,0,0,.7,2.609h0a1.913,1.913,0,0,1,.957,1.657,1.913,1.913,0,0,1-.957,1.657h0a1.9,1.9,0,0,0-.7,2.6h0l.632,1.089A1.913,1.913,0,0,0,3.5,15.7h0a1.895,1.895,0,0,1,2.6.7,1.831,1.831,0,0,1,.256.915h0a1.913,1.913,0,0,0,1.913,1.913H9.519a1.913,1.913,0,0,0,1.913-1.9h0a1.9,1.9,0,0,1,1.913-1.913,1.95,1.95,0,0,1,.915.256h0a1.913,1.913,0,0,0,2.609-.7h0l.659-1.1a1.9,1.9,0,0,0-.7-2.609h0a1.9,1.9,0,0,1-.7-2.609,1.876,1.876,0,0,1,.7-.7h0a1.913,1.913,0,0,0,.7-2.6h0Z" transform="translate(0.779 0.778)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                      <circle id="Ellipse_737" cx="2.636" cy="2.636" r="2.636" transform="translate(7.039 7.753)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <ul class="dropdown-menu dropdown-items px-0 py-2" aria-labelledby="navbarDropdown" style="position:absolute ; left:-60px" >

                            <li><a class="dropdown-item " href="#"  style="color:#062C4C" [routerLink]="['/setting/profile']">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14.162" height="17.685" viewBox="0 0 14.162 17.685">
                                <g id="Group_68955" data-name="Group 68955" transform="translate(0.5 0.5)">
                                  <g id="Profile">
                                    <circle id="Ellipse_736" cx="4.147" cy="4.147" r="4.147" transform="translate(2.431)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Path_33945" d="M0,2.618a1.923,1.923,0,0,1,.191-.842A3.509,3.509,0,0,1,2.638.369,14.571,14.571,0,0,1,4.672.083a21.745,21.745,0,0,1,3.806,0,14.738,14.738,0,0,1,2.034.286,3.376,3.376,0,0,1,2.447,1.406,1.97,1.97,0,0,1,0,1.692,3.343,3.343,0,0,1-2.447,1.4,13.641,13.641,0,0,1-2.034.294,22.413,22.413,0,0,1-3.1.048,3.529,3.529,0,0,1-.707-.048,13.387,13.387,0,0,1-2.026-.294,3.359,3.359,0,0,1-2.455-1.4A1.978,1.978,0,0,1,0,2.618Z" transform="translate(0 11.445)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                  </g>
                                </g>
                              </svg>

                              {{ 'header.account' | translate }}</a>
                            </li>

                            <li><hr class="dropdown-divider"></li>

                            <li><a class="dropdown-item " href="#"  style="color:#062C4C" [routerLink]="['/setting/notify']">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17.003" viewBox="0 0 14 17.003">
                                <g id="Group_68955" data-name="Group 68955" transform="translate(-1354 -174.5)">
                                  <g id="Iconly_Light_Notification" data-name="Iconly/Light/Notification" transform="translate(1354.5 175)">
                                    <g id="Notification">
                                      <path id="Path_425" d="M0,9.43V9.255A2.971,2.971,0,0,1,.461,7.8a3.962,3.962,0,0,0,.913-1.851c0-.533,0-1.074.045-1.607A5.034,5.034,0,0,1,6.47,0H6.53a5.047,5.047,0,0,1,5.06,4.342c.045.533,0,1.074.037,1.607a4.032,4.032,0,0,0,.913,1.859A2.893,2.893,0,0,1,13,9.255v.168a2.935,2.935,0,0,1-.645,1.912,3.387,3.387,0,0,1-2.181,1.1,32.959,32.959,0,0,1-7.352,0,3.424,3.424,0,0,1-2.181-1.1A2.967,2.967,0,0,1,0,9.43Z" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                      <path id="Path_421" d="M0,0A2.316,2.316,0,0,0,1.558.9,2.287,2.287,0,0,0,3.279.4a2.255,2.255,0,0,0,.4-.4" transform="translate(4.63 15.081)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              {{ 'header.notifications' | translate }}</a>
                            </li>

                            <li><hr class="dropdown-divider"></li>

                            <li>
                              <a class="dropdown-item " href="#"  style="color:#062C4C" [routerLink]="['/orders/all']">
                              <svg xmlns="http://www.w3.org/2000/svg" width="15.665" height="15.41" viewBox="0 0 15.665 15.41">
                                <g id="Group_68956" data-name="Group 68956" transform="translate(-316.25 -1084.5)">
                                  <g id="Document" transform="translate(316.75 1085)">
                                    <path id="Stroke_1" data-name="Stroke 1" d="M6.354.5H0" transform="translate(4.176 10.046)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Stroke_2" data-name="Stroke 2" d="M3.929.5H0" transform="translate(6.601 6.769)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Stroke_3" data-name="Stroke 3" d="M2.424.5H0" transform="translate(4.176 3.5)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Stroke_4" data-name="Stroke 4" d="M10.7,0,3.933,0C1.5.016,0,1.438,0,3.606v7.2c0,2.179,1.515,3.606,3.965,3.606l6.766,0c2.429-.013,3.934-1.436,3.934-3.6v-7.2C14.665,1.427,13.149,0,10.7,0Z" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                  </g>
                                </g>
                              </svg>
                              {{ 'header.myOrders' | translate }}</a>
                            </li>

                            <li><hr class="dropdown-divider"></li>

                            <li><a class="dropdown-item " routerLink="['/']" (click)="logout()">
                              <svg class="mr-4" xmlns="http://www.w3.org/2000/svg" width="15.67" height="15.256" viewBox="0 0 15.67 15.256">
                                <g id="Group_68959" data-name="Group 68959" transform="translate(-323.455 -1144.5)">
                                  <g id="Group_68957" data-name="Group 68957">
                                    <g id="Logout" transform="translate(323.955 1145)">
                                      <path id="Stroke_1" data-name="Stroke 1" d="M0,3.559V2.84A2.84,2.84,0,0,1,2.84,0H6.6A2.84,2.84,0,0,1,9.435,2.84v8.577A2.84,2.84,0,0,1,6.6,14.256H2.832A2.832,2.832,0,0,1,0,11.425V10.7" transform="translate(5.235)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                      <path id="Stroke_3" data-name="Stroke 3" d="M0,.5H9.279" transform="translate(0 6.628)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                      <path id="Stroke_5" data-name="Stroke 5" d="M2.256,0,0,2.246,2.256,4.493" transform="translate(0 4.882)" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              {{ 'header.signOut' | translate }}</a>
                            </li>

                          </ul>
                      </li>
                  </div>
                  <div class="d-flex">
                    <li class="nav-item icons mx-1 align-self-center">
                      <div class="btn-group mr-3 mb-2" dropdown>
                        <button id="langToggle" dropdownToggle type="button" aria-controls="dropdown-basic" style="background-color: transparent;">
                          <img src="{{selectedFlag}}"  alt="" srcset="">
                          <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-items px-0 py-2"
                            role="menu" aria-labelledby="langToggle" >

                          <li *ngFor="let lang of langs" role="menuitem">
                              <a class="dropdown-item" (click)="changeLang(lang.lang,lang.flag);translate.use(lang.lang)" href="javascript:void(0)">
                                <img src="{{lang.flag}}" width="20" class="mx-2">
                                {{lang.name}}
                              </a>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li class=" nav-item  icon mx-1 align-self-center" *ngIf="!auth && !user">
                      <button class="order btn outline-dark px-1" [routerLink]="['/auth/login']"> {{ 'auth.login' | translate }}  </button>
                    </li>

                    <li class=" nav-item  icon mx-1 align-self-center" *ngIf="!auth">
                      <button class="order btn btn-success" (click)="openInstantOrder()"> {{ 'instantOrder.instantOrders' | translate }} </button>
                    </li>

                  </div>
                </ul>

              </div>
        </div>
    </nav>
 </section>
   <ng-template #template>

  <div class="modal-body">
    <div>
      <input class="mt-1  form-control align-self-center" style="padding:8px ; border-radius: 27px; display: inline; border:none ; background-color:#E8ECEF ;text-indent: 25px;" type="text" placeholder=" {{ 'top-bar.searchParts' | translate }}" aria-label="Search"  (change)="setSearchValue($event)">
      <a class="icon_template" (click)="searchProducts(2)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
      </a>
    </div>

  </div>
</ng-template>   -->

  <section>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <a *ngIf="hide_search_icon_in_filter_page" class="navbar-brand" href=" " aria-label="oget"><img src="assets/images/header/updated/o_getblue.png" height="100px" width="100px" class="img-fluid" alt="logo" srcset=""> </a>
            <button class="navbar-toggler" (click)="collaps_btn=!collaps_btn">
              <span class="navbar-toggler-icon"></span>
            </button> 
              
            <ul class="search_list list-unstyled col-2 align-self-center justify-content-between pe-0 m-0" *ngIf="!hide_search_icon_in_filter_page">
              <li class="nav-item icon align-self-center col-auto">
                  <a (click)="doFilter()">
                    <img src="assets/images/filter.svg" height="18px" width="18px" alt="icon">
                </a>
              </li>

              <li class="  nav-item  icon col-auto mx-3"  >
                <a>
                  <img src="assets/images/search.svg" height="18px" width="18px" alt="icon">  
                </a> 
              </li> 

              <!-- (click)="openModal(template)" -->
              <!-- <li class="nav-item  icon  align-self-center col-auto">
                <a (click)="showCart()" id="showCart" href="javascript:void(0)">
                  <img src="assets/images/header/head_cart.svg" height="20px" width="20px" alt="icon">
              </a>
            </li> -->
            </ul> 

            <div class="toggleShow " [ngClass]="{'collapse navbar-collapse': collaps_btn==true , 'navbar-collapse': collaps_btn==false}" id="navbarText" style="justify-content:end">
              <ul class="navbar-nav m-auto text-center pb-1 px-0" *ngIf="!auth">
                  <li class="nav-item" (click)="closeNav()">
                    <a class="nav-link" [routerLink]="['/']" [routerLinkActive]="'active-nav'" [routerLinkActiveOptions]={exact:true}>{{ 'header.home' | translate }} </a>
                  </li>

                  <li class="nav-item" (click)="closeNav()">
                    <a class="nav-link" [routerLink]="['/categories']" [routerLinkActive]="'active-nav'"> {{ 'main.mainCategories' | translate }}</a>
                  </li>

                  <li class="nav-item" (click)="closeNav()">
                    <a class="nav-link" [routerLink]="['/brands']" [routerLinkActive]="'active-nav'"> {{ 'header.brands' | translate }}</a>
                  </li>
                  
                  <li class="nav-item" (click)="closeNav()">
                    <a class="nav-link" [routerLink]="['/about-us']" [routerLinkActive]="'active-nav'"> {{ 'header.aboutus' | translate }}</a>
                  </li>

                  <!-- <li class="nav-item" (click)="closeNav()">
                    <a class="nav-link" [routerLink]="['/contact-us']" [routerLinkActive]="'active-nav'"> {{ 'header.contactUs' | translate }}</a>
                  </li> -->
              </ul>

              <ul class="navbar-nav mb-lg-0 navbar-text me-2 px-0" style=" min-height:48px ;">
                <div class="d-flex align-self-center" *ngIf="!auth">

                    <li class="nav-item icon mt-3 mx-1 align-self-center search"  *ngIf="show_search&& hide_search_icon_in_filter_page">
                      <input class="mt-1  form-control align-self-center" style="padding:8px ; border-radius: 27px; display: inline; border:none ; background-color:#E8ECEF ;" type="text" placeholder=" {{ 'top-bar.searchParts' | translate }}" aria-label="Search" #search (change)="setSearchValue($event)">
                        <a (click)="searchProducts(1)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                          </svg>
                        </a>
                    </li>

                    <li class="search-field nav-item pointer icon  align-self-center " *ngIf="hide_search_icon_in_filter_page">
                      <a (click)="show_search=true ; showsearch=false ; showheart=true ;showcart =true; showsetting=true"  *ngIf="!show_search  && showsearch==true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                          <g id="Group_69049" data-name="Group 69049" transform="translate(-1295 -30)">
                            <g id="Group_12543" data-name="Group 12543" transform="translate(29)">
                              <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1266 30)" fill="#062c4c" opacity="0.09"/>
                              <g id="Iconly_Light_Search" data-name="Iconly/Light/Search" transform="translate(1280 43)">
                                <g id="Search" transform="translate(-0.778 -0.778)">
                                  <circle id="Ellipse_739" cx="8.989" cy="8.989" r="8.989" transform="translate(0.778 0.778)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Line_181" d="M0,0,3.524,3.515" transform="translate(16.018 16.485)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>

                        <svg *ngIf="!showsearch " xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                          <g id="Group_69049" data-name="Group 69049" transform="translate(-1295 -30)">
                            <g id="Group_12543" data-name="Group 12543" transform="translate(29)">
                              <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1266 30)" fill="#062c4c" />
                              <g id="Iconly_Light_Search" data-name="Iconly/Light/Search" transform="translate(1280 43)">
                                <g id="Search" transform="translate(-0.778 -0.778)">
                                  <circle id="Ellipse_739" cx="8.989" cy="8.989" r="8.989" transform="translate(0.778 0.778)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Line_181" d="M0,0,3.524,3.515" transform="translate(16.018 16.485)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </a>
                    </li>

                      <!-- cart -->
                    <li class="nav-item pointer icon align-self-center" (click)="closeNav()">
                        <a (click)="showCart() ; showcart=false; showheart=true ; showsetting=true  ;showsearch=true " id="showCart" href="javascript:void(0)" aria-label="oget">
                          <svg *ngIf="showcart  " xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                            <g id="Group_69047" data-name="Group 69047" transform="translate(-1435 -30)">
                              <g id="Group_12421" data-name="Group 12421" transform="translate(13)">
                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" opacity="0.09"/>
                                <g id="Iconly_Light_Buy" data-name="Iconly/Light/Buy" transform="translate(1433 42)">
                                  <g id="Buy">
                                    <path id="Stroke_1" data-name="Stroke 1" d="M.975,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.975,0Z" transform="translate(4.99 21.057)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Stroke_3" data-name="Stroke 3" d="M.977,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.977,0Z" transform="translate(19.355 21.057)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Stroke_5" data-name="Stroke 5" d="M0,0,2.656.457l1.23,14.58a2.3,2.3,0,0,0,2.294,2.1H20.111a2.3,2.3,0,0,0,2.279-1.965L23.6,6.839a1.706,1.706,0,0,0-1.694-1.948H3.082" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Stroke_7" data-name="Stroke 7" d="M0,.5H3.54" transform="translate(14.524 9.088)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>

                          <svg *ngIf="!showcart " xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 46 46">
                            <g id="Group_69047" data-name="Group 69047" transform="translate(-1435 -30)">
                              <g id="Group_12421" data-name="Group 12421" transform="translate(13)">
                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" />
                                <g id="Iconly_Light_Buy" data-name="Iconly/Light/Buy" transform="translate(1433 42)">
                                  <g id="Buy">
                                    <path id="Stroke_1" data-name="Stroke 1" d="M.975,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.975,0Z" transform="translate(4.99 21.057)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Stroke_3" data-name="Stroke 3" d="M.977,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.977,0Z" transform="translate(19.355 21.057)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Stroke_5" data-name="Stroke 5" d="M0,0,2.656.457l1.23,14.58a2.3,2.3,0,0,0,2.294,2.1H20.111a2.3,2.3,0,0,0,2.279-1.965L23.6,6.839a1.706,1.706,0,0,0-1.694-1.948H3.082" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Stroke_7" data-name="Stroke 7" d="M0,.5H3.54" transform="translate(14.524 9.088)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>

                        </a>
                    </li>
                    
                    <!-- setting -->
                    <li class="nav-item  icon dropdown  align-self-center" style="position:relative ; top:0px ; left:-1px"  *ngIf="user" (click)="showsetting=false ;showheart =true;showcart =true ;showsearch=true ">
                      <div class="btn-group" dropdown>
                          <img *ngIf="showsetting " src="assets/images/header/setting_white.svg" id="setting" dropdownToggle type="button" aria-controls="dropdown-basic">
                          <img *ngIf="!showsetting " src="assets/images/header/setting.svg" id="setting" dropdownToggle type="button" aria-controls="dropdown-basic">

                          <ul style="position:absolute!important;" id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-items px-0 py-2"  role="menu" aria-labelledby="setting">
                            
                            <li role="menuitem" (click)="closeNav()">
                              <a class="dropdown-item" [routerLink]="['/setting/profile']">
                                <img src="assets/images/header/personal.svg" class="image-fluid" width="17px">
                                {{ 'header.account' | translate }} </a>
                            </li>

                            <li role="menuitem" (click)="closeNav()">
                              <a class="dropdown-item" [routerLink]="['/setting/notify']">
                              <img src="assets/images/header/notify.svg" class="image-fluid" width="17px">
                              {{ 'header.notifications' | translate }} </a>
                            </li>

                            <li role="menuitem" (click)="closeNav()">
                              <a class="dropdown-item" [routerLink]="['/orders/all']">
                              <svg xmlns="http://www.w3.org/2000/svg" width="15.665" height="15.41" viewBox="0 0 15.665 15.41">
                                <g id="Group_68956" data-name="Group 68956" transform="translate(-316.25 -1084.5)">
                                  <g id="Document" transform="translate(316.75 1085)">
                                    <path id="Stroke_1" data-name="Stroke 1" d="M6.354.5H0" transform="translate(4.176 10.046)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Stroke_2" data-name="Stroke 2" d="M3.929.5H0" transform="translate(6.601 6.769)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Stroke_3" data-name="Stroke 3" d="M2.424.5H0" transform="translate(4.176 3.5)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Stroke_4" data-name="Stroke 4" d="M10.7,0,3.933,0C1.5.016,0,1.438,0,3.606v7.2c0,2.179,1.515,3.606,3.965,3.606l6.766,0c2.429-.013,3.934-1.436,3.934-3.6v-7.2C14.665,1.427,13.149,0,10.7,0Z" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                  </g>
                                </g>
                              </svg>
                              {{ 'header.myOrders' | translate }} </a>
                            </li>

                            <li role="menuitem" (click)="closeNav()">
                              <a class="dropdown-item" routerLink="['/']" (click)="logout()">
                              <img src="assets/images/header/logout.svg" class="image-fluid" width="17px">
                              {{ 'header.signOut' | translate }} </a>
                            </li>

                          </ul>
                      </div>
                    </li>

                    <!-- wishlist -->
                    <li class="nav-item  icon align-self-center" (click)="closeNav()">
                      <a [routerLink]="['/setting/wishlist']" (click)="showheart=false ;showcart =true; showsetting=true ;showsearch=true ">
                      <svg *ngIf="showheart" xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 46 46">
                          <g id="Group_69043" data-name="Group 69043" transform="translate(-1365 -30)">
                            <g id="Group_12422" data-name="Group 12422" transform="translate(13)">
                              <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1352 30)" fill="#062c4c" opacity="0.09"/>
                              <g id="Iconly_Light_Heart" data-name="Iconly/Light/Heart" transform="translate(1363 42)">
                                <g id="Heart">
                                  <path id="Path_33961" d="M11.834,22.813a44.517,44.517,0,0,1-7.694-5.991A15.321,15.321,0,0,1,.477,10.983C-.9,6.709.708,1.816,5.2.367a7.639,7.639,0,0,1,6.932,1.168h0A7.65,7.65,0,0,1,19.066.367C23.56,1.816,25.177,6.709,23.8,10.983a15.321,15.321,0,0,1-3.662,5.839,44.517,44.517,0,0,1-7.694,5.991l-.3.187Z" transform="translate(0 0)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Path_33964" d="M0,0A3.554,3.554,0,0,1,2.449,3.095" transform="translate(16.917 5.179)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>


                        <svg *ngIf="!showheart " xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 46 46">
                          <g id="Group_69045" data-name="Group 69045" transform="translate(-1365 -30)">
                            <g id="Group_12422" data-name="Group 12422" transform="translate(13)">
                              <circle id="Ellipse_2" data-name="Ellipse 2" cx="23" cy="23" r="23" transform="translate(1352 30)" fill="#062c4c"/>
                              <g id="Iconly_Light_Heart" data-name="Iconly/Light/Heart" transform="translate(1363 42)">
                                <g id="Heart">
                                  <path id="Path_33961" d="M11.834,22.813a44.517,44.517,0,0,1-7.694-5.991A15.321,15.321,0,0,1,.477,10.983C-.9,6.709.708,1.816,5.2.367a7.639,7.639,0,0,1,6.932,1.168h0A7.65,7.65,0,0,1,19.066.367C23.56,1.816,25.177,6.709,23.8,10.983a15.321,15.321,0,0,1-3.662,5.839,44.517,44.517,0,0,1-7.694,5.991l-.3.187Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Path_33964" d="M0,0A3.554,3.554,0,0,1,2.449,3.095" transform="translate(16.917 5.179)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </a>
                    </li>

                    <!-- cart -->     
                    <!-- <li class="nav-item search-field icon align-self-center">
                      <a (click)="showCart() ; showcart=false; showheart=true ; showsetting=true  ;showsearch=true " id="showCart" href="javascript:void(0)">
                        <svg *ngIf="showcart  " xmlns="http://www.w3.org/2000/svg" width="40"  viewBox="0 0 46 46">
                          <g id="Group_69047" data-name="Group 69047" transform="translate(-1435 -30)">
                            <g id="Group_12421" data-name="Group 12421" transform="translate(13)">
                              <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" opacity="0.09"/>
                              <g id="Iconly_Light_Buy" data-name="Iconly/Light/Buy" transform="translate(1433 42)">
                                <g id="Buy">
                                  <path id="Stroke_1" data-name="Stroke 1" d="M.975,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.975,0Z" transform="translate(4.99 21.057)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Stroke_3" data-name="Stroke 3" d="M.977,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.977,0Z" transform="translate(19.355 21.057)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Stroke_5" data-name="Stroke 5" d="M0,0,2.656.457l1.23,14.58a2.3,2.3,0,0,0,2.294,2.1H20.111a2.3,2.3,0,0,0,2.279-1.965L23.6,6.839a1.706,1.706,0,0,0-1.694-1.948H3.082" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Stroke_7" data-name="Stroke 7" d="M0,.5H3.54" transform="translate(14.524 9.088)" fill="none" stroke="#062c4c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>

                        <svg *ngIf="!showcart " xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 46 46">
                          <g id="Group_69047" data-name="Group 69047" transform="translate(-1435 -30)">
                            <g id="Group_12421" data-name="Group 12421" transform="translate(13)">
                              <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(1422 30)" fill="#062c4c" />
                              <g id="Iconly_Light_Buy" data-name="Iconly/Light/Buy" transform="translate(1433 42)">
                                <g id="Buy">
                                  <path id="Stroke_1" data-name="Stroke 1" d="M.975,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.975,0Z" transform="translate(4.99 21.057)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Stroke_3" data-name="Stroke 3" d="M.977,0A.972.972,0,1,1,0,.972.975.975,0,0,1,.977,0Z" transform="translate(19.355 21.057)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Stroke_5" data-name="Stroke 5" d="M0,0,2.656.457l1.23,14.58a2.3,2.3,0,0,0,2.294,2.1H20.111a2.3,2.3,0,0,0,2.279-1.965L23.6,6.839a1.706,1.706,0,0,0-1.694-1.948H3.082" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                  <path id="Stroke_7" data-name="Stroke 7" d="M0,.5H3.54" transform="translate(14.524 9.088)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>

                      </a>
                  </li> -->
                </div>

                <div class="d-flex">
                  <li class=" nav-item mx-1 icon align-self-center" *ngIf="!auth && !user" (click)="closeNav()">
                    <button class="order btn outline-dark px-3 " [routerLink]="['/auth/login']"> {{ 'auth.login' | translate }}  </button>
                  </li>

                  <li class=" nav-item  icon mx-1 align-self-center" *ngIf="!auth" (click)="closeNav()">
                    <button class="order btn btn-success " (click)="openInstantOrder()"> {{ 'instantOrder.instantOrders' | translate }} </button>
                  </li>

                  <!-- <li class="nav-item icons mx-1 align-self-center">
                    <div class="btn-group mr-3" dropdown>
                      <button id="langToggle" dropdownToggle type="button" aria-controls="dropdown-basic" style="background-color: transparent;">
                        <img src="{{selectedFlag}}"  alt="" srcset="">
                        <span class="caret"></span>
                      </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-items px-0 py-2"
                          role="menu" aria-labelledby="langToggle" >

                        <li *ngFor="let lang of langs" role="menuitem">
                            <a class="dropdown-item" (click)="changeLang(lang.lang,lang.flag);translate.use(lang.lang)" href="javascript:void(0)" aria-label="oget">
                              <img src="{{lang.flag}}" width="20" class="mx-2">
                              {{lang.name}}
                            </a>
                        </li>
                      </ul>
                    </div>
                  </li>    -->
                </div>
              </ul>
            </div> 
        </div>
      </nav>
  </section>

  <ng-template #template>
    <div class="modal-body">
      <div>
        <input class="mt-1  form-control align-self-center" style="padding:8px ; border-radius: 27px; display: inline; border:none ; background-color:#E8ECEF ;text-indent: 25px;" type="text" placeholder=" {{ 'top-bar.searchParts' | translate }}" aria-label="Search"  (change)="setSearchValue($event)">
        <a class="icon_template" (click)="searchProducts(2)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
        </a>
      </div>

    </div>
  </ng-template>  


  <!-- <header class="header">
    <nav class="navbar">
      <a class="brand" href="#">Brand</a>
      <input type="checkbox" id="nav" class="hidden">
      <label for="nav" class="nav-toggle">
          <span></span>
          <span></span>
          <span></span>
       </label>
      <div class="wrapper">
        <ul class="menu">
          <li class="menu-item"><a href="#">Home</a></li>
          <li class="menu-item"><a href="#">About</a></li>
          <li class="menu-item"><a href="#">Projects</a></li>
          <li class="menu-item"><a href="#">comtacts</a></li>
         
        </ul>
      </div>
    </nav>
  </header> -->