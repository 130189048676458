import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'
// import { AuthGuard } from './guards/auth.guard';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
 
const routes: Routes = [
  // auto redirect to home page
   { path:'', redirectTo:'home', pathMatch:'full' } ,
   
   {
    path:'',
    component:AppLayoutComponent,
     children:[
        {
          path:'',
          loadChildren: () => import('./components/home-info/home.modules').then(m => m.HomeInfoModule)
        },
        {
          path:'setting',
          loadChildren: () => import('./components/setting-info/setting.modules').then(m => m.SettingInfoModule)
        },
        {
          path:'orders',
          loadChildren: () => import('./components/orders-info/orders.modules').then(m => m.OrdersInfoModule)
        },
        {
          path:'products',
          loadChildren: () => import('./components/products-info/products.modules').then(m => m.ProductsInfoModule)
        },
      
     ]
   },

 {
  path:'',
  component:AuthLayoutComponent ,
  children:[
    {
      path: 'auth',
      loadChildren: () => import('./components/Auth/auth.modules').then(m => m.AuthModule)
    }
  ]
},

// {
//   path:'mobile',
//   component:MobileLayoutComponent ,
//   children:[
//     {
//       path: '',
//       loadChildren: () => import('./components/home-info/home.modules').then(m => m.HomeInfoModule)
//     },
//     {
//       path: 'setting-info',
//       loadChildren: () => import('./components/setting-info/setting.modules').then(m => m.SettingInfoModule)
//     },
//     {
//       path: 'orders-info',
//       loadChildren: () => import('./components/orders-info/orders.modules').then(m => m.OrdersInfoModule)
//     },
//     {
//       path: 'products-info',
//       loadChildren: () => import('./components/products-info/products.modules').then(m => m.ProductsInfoModule)
//     }
// ]
// },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule ]
})
export class AppRoutingModule { }
