import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public filter_title = [] ;
  toggleFilter:boolean=false;
  emmiter:EventEmitter<any>= new EventEmitter();
  public categoriesService: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public brandsService: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public allModelService: BehaviorSubject<Array<any>> = new BehaviorSubject([]); // all models of all brands
  public modelService: BehaviorSubject<Array<any>> = new BehaviorSubject([]); // all models of specific brand
  public yearsService: BehaviorSubject<Array<any>> = new BehaviorSubject([]); // all years 
  public reviewsService: BehaviorSubject<Array<any>> = new BehaviorSubject([]); // all home reviews
  public bestProductsService: BehaviorSubject<Array<any>> = new BehaviorSubject([]); // all bestproducts
  public bannersService: BehaviorSubject<Array<any>> = new BehaviorSubject([]); // all home banners 


  private searchRefresh:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getRefreshSearch(): Observable<boolean> { return this.searchRefresh.asObservable()}  //recieve
  public setRefreshSearch(value: any):void { this.searchRefresh.next(value)} //send
  
  private refresh:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getRefresh(): Observable<boolean> { return this.refresh.asObservable()}  //recieve
  public setRefresh(value: any):void { this.refresh.next(value) } //send


  private refresh2:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getRefresh2(): Observable<any> { return this.refresh2.asObservable()}  //recieve
  public setRefresh2(value: any):void { this.refresh2.next(value) } //send


  public brand_id:any ; //custom phone
  constructor(private http:HttpClient) { }
     
 
Allhome(){
  return this.http.get(`${environment.urlPoint}/home/show`)
}
  
getCities(){
  return this.http.get(`${environment.urlPoint}/cities/all`)
}

getCountries(){
  return this.http.get(`${environment.urlPoint}/countries/all`)
   
}

getCategories(){
  return this.http.get(`${environment.urlPoint}/categories/all`)
}
  
getCategoryDetailsById(category_id){
  return this.http.get(`${environment.urlPoint}/category/show?category_id=${category_id}`) 
}

allMainCategories(){
    return this.http.get(`${environment.urlPoint}/categories/all`)
}

allProductsByMainCategoryId(){
}

allBrands(){
    return this.http.get(`${environment.urlPoint}/brands/all`) 
}

getBrandDetailsById(brand_id){
  return this.http.get(`${environment.urlPoint}/brand/show?brand_id=${brand_id}`) 
}

allModlesOrProductsByBrandId(brand_ids:any){
  const formData:FormData = new FormData()
    for (let i = 0; i < brand_ids.length; i++) {
      formData.append('brand_ids['+i+']',brand_ids[i])
    }
    return this.http.post(`${environment.urlPoint}/models/all`,formData)
 
  //   return this.http.get(`${environment.urlPoint}/models/all?brand_id=${brand_id}`) 
}

getModelDetailsById(model_id){
  return this.http.get(`${environment.urlPoint}/model/show?model_id=${model_id}`) 
}

allManufactureDates(){
    return this.http.get(`${environment.urlPoint}/manufacture-dates/all`) 
}
  
showTopSellerLimit(f:any) {
  for (const [key, value] of Object.entries(f)) {
      if(value!=null) {
        return this.http.get(`${environment.urlPoint}/best-products/all?session_id=${f.session_id}&page=${f.page}`) 
      }
    else { 
      return this.http.get(`${environment.urlPoint}/best-products/all?page=${f.page} `) 
    }
  }
  // if (f.session_id!=null ||f.session_id!=undefined) { 
  //   return this.http.get(`${environment.urlPoint}/best-products/all?session_id=${f.session_id}&page=${f.page}`) 
  // }
  // else { 
  //   return this.http.get(`${environment.urlPoint}/best-products/all?page=${f.page} `) 
  // }
}

// filteredProducts(f){
//   const formData:any = new FormData()
//   for (const [key, value] of Object.entries(f)) {
//     if(value!=null&& !Array.isArray(value)) {
//       formData.append(key,`${value}`)
//     }
//     else if(Array.isArray(value) && value.length!=0) {
//       for(let i=0 ; i<value.length ; i++)
//       if(value[i]!='') {
//         formData.append( key+'['+i+']'  , value[i])
//       }
//     }
//   }
   
//  return this.http.post(`${environment.urlPoint}/products/filter`,formData)
// }

filterProducts(f){
 
  //  const formData:any = new FormData()
  //  if(f.page!=null){
  //   formData.append('page',f.page)
  // }
  //   if(f.session_id!=null){
  //     formData.append('session_id',f.session_id)
  //   }
  //   if(f.product_name!=null){
  //     formData.append('product_name',f.product_name)
  //   }
  //   if(f.brand_ids!=null){
  //     for (let i = 0; i < f.brand_ids.length; i++) {
  //      formData.append('brand_ids['+i+']',f.brand_ids[i] )
  //     }
  //   }
  //   if(f.model_ids!=null){
  //     for (let y = 0; y < f.model_ids.length; y++) {
  //      formData.append('model_ids['+y+']',f.model_ids[y] )
  
  //     }
  //   }
  //   if(f.category_ids!=null){
  //     for (let c = 0; c < f.category_ids.length; c++) {
  //      formData.append('category_ids['+c+']',f.category_ids[c] )
  //     }
  //   }
  //   if(f.trader_ids!=null){
  //     for (let m = 0; m < f.trader_ids.length; m++) {
  //      formData.append('trader_ids['+m+']',f.trader_ids[m] )
 
  //     }
  //   }
  //   if(f.subcategory_ids!=null){
  //     for (let n = 0; n < f.subcategory_ids.length; n++) {
  //      formData.append('subcategory_ids['+n+']',f.subcategory_ids[n] )
  //      }
  //   }
   
  //   if(f.manufacture_date_id!=null) {
     
  //       formData.append('manufacture_date_id',f.manufacture_date_id )
   
  //   }
  //   if(f.VIN!=null){
  //       formData.append('VIN',f.VIN)
  //   }
  //   if(f.min_price!=null){
  //       formData.append('min_price',f.min_price)
  //   }
  //   if(f.max_price!=null){
  //       formData.append('max_price',f.max_price)
  //   }
  //   if(f.rating!=null){
      
  //     for (let r = 0; r < f.rating.length; r++) {
  //       formData.append('rating['+r+']',f.rating[r] )
  //      }
  //   }
  
  const formData:any = new FormData()
  for (const [key, value] of Object.entries(f)) {
    if(value!=null&& !Array.isArray(value)) {
      formData.append(key,`${value}`)
    }
    else if(Array.isArray(value) && value.length!=0) {
      for(let i=0 ; i<value.length ; i++)
      if(value[i]!='') {
        formData.append( key+'['+i+']'  , value[i])
      }
    }
  }
   return this.http.post(`${environment.urlPoint}/products/filter`,formData)
}
 
filterByName(form:any){
  const formData:any = new FormData()
  for (const [key, value] of Object.entries(form)) {
    if(value!=null && !Array.isArray(value)) {
      formData.append(key,`${value}`)
    }
   if(Array.isArray(value) && value.length!=0) {
    for(let i=0 ; i<value.length ; i++) { 
          if(value[i]!='') {
            formData.append( key+'['+i+']' , value[i])
          }
        }
    }
  }
  return this.http.post(`${environment.urlPoint}/products/entity/search`,formData)
}

getFooter(){
  return this.http.get(`${environment.urlPoint}/footer/show`) 
}

createInstantOrder(form){
  return this.http.post(`${environment.urlPoint}/user/instant-order/create`,form)
}

brandDetails(brand_id){
  return this.http.get(`${environment.urlPoint}/brand/show?brand_id=${brand_id}`) 
}

getBanner() {
  return this.http.get(`${environment.urlPoint}/banner/show`) 
}

homeReviews() {
  return this.http.get(`${environment.urlPoint}/home/reviews`) 
}

addAddress(form){
  return this.http.post(`${environment.urlPoint}/user/address/create`,form)
}

editAddress(form){
  return this.http.post(`${environment.urlPoint}/user/address/update`,form)
}


 

}
