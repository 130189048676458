import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
 
  public someProp  = new BehaviorSubject<any>(null);
  public storageSub= new Subject<boolean>();
  watch(): Observable<any> { return this.storageSub.asObservable() } 
  user;
   

  constructor(private http: HttpClient , private auth:AuthenticationService) { 
    // this.auth.currentUser.subscribe(currentUser=>this.user=currentUser)
 
  }

  // products(filter) {
  //   const formData: FormData = new FormData();
  //   if(filter.serialnumber)
  //   formData.append("serialnumber", filter.serialnumber);
  //   if(filter.marker_id)
  //   formData.append("marker_id", filter.marker_id);
  //   if(filter.type_id)
  //   formData.append("type_id", filter.type_id);
  //   if(filter.main_specialist_id)
  //   formData.append("main_specialist_id", filter.main_specialist_id);
  //   if(filter.secondary_specialist_id)
  //   formData.append("secondary_specialist_id", filter.secondary_specialist_id);
  //   if(filter.status)
  //   formData.append("status", filter.status);
  //   if(filter.market_id)
  //   formData.append("market_id", filter.market_id);
  //   return this.http.post(`${environment.endPoint}/products`,formData)
  // }
  // getProduct(id){
  //   return this.http.get(`${environment.endPoint}/products/show?product_id=${id}`)
  // }

  addRate(form){
    const formData: FormData = new FormData();
    formData.append("review",form.review);
    formData.append("rating",form.rating);
    formData.append("product_id", form.product_id);
  
    new Response(formData).text().then(console.log)
    return this.http.post(`${environment.urlPoint}/user/product/rating/create`,formData)
  }
 
  getSubCategoryByCategoryId(category_id){
    return this.http.get(`${environment.urlPoint}/subcategories/all?category_id=${category_id}`)
  }

  getAllTraders(){
    return this.http.get(`${environment.urlPoint}/traders/all`)
  }

  getProductDetails(f){
    if(f.case==1){
      return this.http.get(`${environment.urlPoint}/product/show?product_id=${f.product_id}&session_id=${f.session_id}`)
    }
    else if (f.case==2 || f.case==3) {
      return this.http.get(`${environment.urlPoint}/product/show?product_id=${f.product_id}`)
    }
  // product_id,session_id
  //  console.log("service session", session_id)
  //   if (session_id!=null){
  //     console.log("not null")
  //     return this.http.get(`${environment.urlPoint}/product/show?product_id=${product_id}&session_id=${session_id}`)
  //   }
  //   else {
  //     console.log("null")
  //     return this.http.get(`${environment.urlPoint}/product/show?product_id=${product_id}`)
  //   }
  
  }

  // getFeaturedProducts(product_id){
  //   return this.http.get(`${environment.urlPoint}/featured-products/all?product_id=${product_id}`)
  // }
  
  getReviewsOfProducts(product_id){
    return this.http.get(`${environment.urlPoint}/product/reviews?product_id=${product_id}`)
  }


  getSearchProduct(word:any){
    return this.http.get(`${environment.urlPoint}/product/search-by-name?name=${word}`)
  }

  
}
