import { Component, OnInit } from '@angular/core';
import { InstantOrderComponent } from 'src/app/shared/instant-order-dialog/instant-order.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-scrollable-navbar',
  templateUrl: './scrollable-navbar.component.html',
  styleUrls: ['./scrollable-navbar.component.scss']
})
export class ScrollableNavbarComponent implements OnInit {

  constructor( public dialog: MatDialog ) { 
   }

  ngOnInit(): void {
  }
  
  openInstantOrder(){
    const dialogRef = this.dialog.open(InstantOrderComponent, {
       width: '800px',
     });
  }
}
