<section class="edit_address">
    <div class="container-fluid">
        <div class="row">
            <div class="d-flex justify-content-between">
                <h4 class="text-dark-blue fw-bold py-2"> {{'main.editAddress' | translate }} </h4> 
                <div class="close float-end"> 
                    <button class="btn " mat-button [mat-dialog-close]="true" > x </button>
                </div>
            </div> 
            <form [formGroup]='form' (ngSubmit)="submit()">
                <div class="row mt-3">
                  <div class="form-group col-md-6 col-12 my-2">
                    <input type="text" class="form-control" placeholder="{{'placeholders.firstName' | translate }}" formControlName="first_name">
                    <div class="errors" *ngIf="submitted && f.first_name.errors">
                      <p class="alert alert-danger mt-2" *ngIf="f.first_name.errors.required">{{'auth.errorMsg' | translate }}</p>
                    </div>  
                  </div>

                  <div class="form-group col-md-6 col-12 my-2">
                    <input type="text" class="form-control" placeholder="{{'placeholders.lastName' | translate }}" formControlName="last_name">
                    <div class="errors" *ngIf="submitted && f.last_name.errors">
                      <p class="alert alert-danger mt-2" *ngIf="f.last_name.errors.required">{{'auth.errorMsg' | translate }}</p>
                    </div> 
                  </div>

                <!-- </div>

                <div class="row mt-3"> -->
                    <div class="form-group col-md-6 col-12 my-2">
                      <input type="text" class="form-control" placeholder="{{'placeholders.phoneNumber' | translate }}" formControlName="phone">
                      <div class="errors" *ngIf="submitted && f.phone.errors">
                        <p class="alert alert-danger mt-2" *ngIf="f.phone.errors.required">{{'auth.errorMsg' | translate }}</p>
                      </div>
                    </div>

                    <div class="form-group col-md-6 col-12 my-2">
                       <select id="Country" class="form-control" formControlName="country_id">  <!-- form-select-->
                          <option *ngFor="let country of countries" [value]="country.id">{{country.name}} </option>
                        </select>
                        <div class="errors" *ngIf="submitted && f.country_id.errors">
                          <p class="alert alert-danger mt-2" *ngIf="f.country_id.errors.required">{{'auth.errorMsg' | translate }}</p>
                      </div>  
                    </div>

                  <!-- </div>

                  <div class="row mt-3"> -->
                    <div class="form-group col-md-6 col-12 my-2">
                      <input type="text" class="form-control" placeholder="{{'placeholders.address' | translate }}" formControlName="address">
                      <div class="errors" *ngIf="submitted && f.address.errors">
                        <p class="alert alert-danger mt-2" *ngIf="f.address.errors.required">{{'auth.errorMsg' | translate }}</p>
                      </div>  
                    </div>

                    <div class="form-group col-md-6 col-12 my-2">
                       <select id="Country" class="form-control" formControlName="city_id"> <!-- form-select -->
                          <option *ngFor="let city of cities" [value]="city.id"> {{city.name}}</option>
                        </select>
                        <div class="errors" *ngIf="submitted && f.city_id.errors">
                          <p class="alert alert-danger mt-2" *ngIf="f.city_id.errors.required">{{'auth.errorMsg' | translate }}</p>
                      </div>  
                    </div>
                  </div>
                  <div class="float-end mt-3 col-md-6 col-12 edit_btn"> 
                    <button class="btn btn-success w-100"> {{'main.saveChanges' | translate }}  </button> 
                </div>
              </form>
        </div>
     </div>
</section> 