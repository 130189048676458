import { AfterViewInit, Component, ElementRef, OnInit, ViewChild ,Renderer2, Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map} from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import Swal from 'sweetalert2';
import { DOCUMENT } from '@angular/common';
import { PublicfunctionService } from 'src/app/services/publicfunction.service';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, AfterViewInit {

  allcarts;
  subtotal_price:number=0;
  total_price:number=0;
  shipping:number=0 ;
  tax:number=0;
  total_tax:number=0;
  count:number;
  showTotal=false;
  session_id:any
  constructor(private _renderer2: Renderer2, private itemService:PublicfunctionService,
    @Inject(DOCUMENT) private _document: Document, private cart:CartService, private service: AppService,private router: Router , private route:ActivatedRoute){
    this.session_id=localStorage.getItem("session_id")
  }

  ngAfterViewInit(): void {
    // let h
    // var element
    // setTimeout(() =>{ // use setTimeout because #webDesc in in *ngIf or *ngFor
    //    h= this.webDesc.nativeElement.offsetHeight ;
       
    //     element = document.getElementById("desc");
    //     console.log("hhhhhh ",h)
    //     if(h<=50){
    //       console.log("hhhhhh2222",h)
    //       element.style.overflowY='hidden'
    //       element.style.maxHeight='auto' 
    //      }
    //      else {
    //       console.log("hhhhhh333",h)
    //        element.style.overflowY='scroll'
    //        element.style.maxHeight='100px' 
    //      }
    // },3000);
  
    
    // var element = document.getElementById("desc");
    // let h = element.offsetHeight ;
    // console.log("hhh",h)
    // //element.classList.add("mystyle");
   
 
    // let script = document.createElement('script');
    // let element =document.getElementById("desc");
    
    // document.body.appendChild(script);
    // script.type = `text/javascript`;
    // script.setAttribute('async', ''); 
  }

  @ViewChild('webDesc') webDesc:ElementRef ; 

  ngOnInit(): void {
    this.getCarts();
    document.body.style.overflowY = 'hidden';
//  this.itemService.cartItemsService.subscribe((res:any)=>{
//     console.log("Aaaaaar",res )
//   } )
 }  

 
  hideCart(){
     this.cart.storageSub.next(true);
     document.body.style.overflowY = 'scroll';
     // this.cartOpen = !this.cartOpen;
     this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  goToCheckout(){
    this.router.navigate(['/orders/checkout','shopping']);
    this.hideCart()
  }

  cart_info=[]
  getCarts(){
//   this.itemService.getItemsInCart();
 
//   this.itemService.cartItemsService.subscribe((item:any)=>{
//      this.allcarts=item
//   })

//  this.itemService.cartInfoService.subscribe((info:any)=>{
//    this.cart_info=info
//     this.subtotal_price=info[0]
//     this.shipping=info[1]
//     this.total_tax=info[2]
//     this.total_price=info[3]
 
// })
    let session_id = localStorage.getItem("session_id") ;
    this.cart.cartShow(session_id).pipe(map(res=>res['data'])).subscribe((items:any) => {
       if(items!=null){
        this.allcarts=items['cart_items']
 
        console.log("allcarts" ,this.allcarts)
        items.cart_items.forEach(cart => {
   
        this.subtotal_price += (Number(cart.price)* Number(cart.quantity))  ;
        this.cart.getTax_Shipping().subscribe(res=>{
        
          this.shipping=res['data'].shipping_fee;
          this.tax=res['data'].tax ;
       
          this.total_tax=(this.tax/100)*this.subtotal_price ;
         
          this.total_price=(+this.total_tax)+(+this.shipping)+(+this.subtotal_price)
           }) 
     
       });
    }
    else {
      this.allcarts=[]
    }
 
    })
    console.log("sss",  this.subtotal_price    , this.shipping  ,  this.total_tax  ,this.total_price )
   }

  update(product_id,count,b){
    count = +count
  //  console.log(product_id,count,b)
    this.showTotal=true ;
    if(b == false && count>1)
    {
      count--
    }
    if(b == true)
    {
      count++
    }

    let form = {
      product_id:product_id,
      quantity:count,
      session_id: this.session_id||null
    }
   
    this.cart.updatCart(form).subscribe((res:any)=>{
    //  console.log(res)
      if (res.status==true) {
        
        if(b){
          this.subtotal_price = this.subtotal_price +Number(res.data.price)
          
        }else{
        this.subtotal_price = this.subtotal_price -Number(res.data.price)
        
        }
        this.cart.cartShow(form.session_id).pipe(map(res=>res['data'])).subscribe((items:any) => {
          this.allcarts=items['cart_items'];

          this.getTaxandShipping();
        })
       
      }
  
    })
   
  }

  getTaxandShipping(){
    this.cart.getTax_Shipping().subscribe(res=>{
    //console.log('ressssss',this.subtotal_price)
    this.shipping=res['data'].shipping_fee;
    this.tax=res['data'].tax ;
    //console.log('tax',this.tax)
    this.total_tax=Math.ceil((this.tax/100)*this.subtotal_price) ;
   //console.log("total tax",this.total_tax,this.shipping)
    this.total_price=(+this.total_tax)+(+this.shipping)+(+this.subtotal_price)
    })
  }

  deleteProduct(id){
   
    let form={
        cart_item_id:id,
        session_id: this.session_id || null
      }
  
    this.cart.deleteProduct(form).subscribe((res:any)=>{
     // console.log("delllete",res)
       if(res.status == true){
      
        this.cart.cartShow(this.session_id).pipe(map(res=>res['data'])).subscribe((items:any) => {
          this.subtotal_price =0;
          //console.log("itemssss" , items)
          if(items!=null){
            this.allcarts=items['cart_items']
            items.cart_items.forEach(cart => {
            this.subtotal_price += (Number(cart.price)* Number(cart.quantity))  ; 
           });
        }
        else {
          this.allcarts=[]
        }
        this.cart.getTax_Shipping().subscribe(res=>{
          //console.log('ressssss',this.subtotal_price)
          this.shipping=res['data'].shipping_fee;
          this.tax=res['data'].tax ;
          //console.log('tax',this.tax)
          this.total_tax=(this.tax/100)*this.subtotal_price ;
          this.total_price=(+this.total_tax)+(+this.shipping)+(+this.subtotal_price)
          })
    
        })
        Swal.fire(
          'deleted',
          `${res.message}`,
          'success'
        )
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
       }
       else {
        // alert("ay kalam")
        Swal.fire(
          'Failed',
          'Failed to delete Item ',
          'error'
        )
       }
 
    })
  }
    
}
