<section class="instant-card">  <!--style="height:320px;overflow-y: scroll; overflow-x: hidden;"-->
   
        <div class="row justify-content-center">  
            <div class="popup y" id="y"  [ngStyle]="{'min-height': expanded  ? '90vh' : '421px' ,'transition': 'min-height 0.4s ease-out' }"> <!--*ngIf="!response_case  "    popup--> 
               <button class="btn" mat-button [mat-dialog-close]="true"> x </button>
                <owl-carousel-o class="carousel slide carousel-fade"  [options]="customOptions" style="direction:ltr !important; height: 600px;">
                    <!-- <ng-container *ngFor="let banner of banners "> -->
                        <ng-template carouselSlide class="" *ngFor="let item of images">
                            <div class="intro text-center m-auto">
                              <img [src]="item.image" onerror="this.src='assets/images/home/artboard.webp'"  alt="banner">
                              <!-- <img class="" [lazyLoad]="banner" alt="banner" defaultImage="assets/images/home/artboard.webp"> -->
                              <p class="mt-3 fw-bold mx-2"> {{item.title|translate}} </p>   
                              <span class="text-center mx-2"> {{item.description | translate}} </span>
                            </div>
                           
                        </ng-template>
                     <!-- </ng-container> -->
                </owl-carousel-o>
       
                <mat-accordion >
                      <mat-expansion-panel style="height: 0 !important;">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            Personal data
                          </mat-panel-title>
                          <mat-panel-description>
                            Type your name and age
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                    
                        <mat-form-field>
                          <input matInput placeholder="First name">
                        </mat-form-field>
                     
                      </mat-expansion-panel> 
                   
                    <mat-expansion-panel (opened)="panelOpenState = true"(closed)="panelOpenState = false" [expanded]="expanded"> 
                        <mat-expansion-panel-header (click)="changeExpansion()">
                            <mat-panel-description>
                                <h3 class="text-light fw-bold"> {{'instantOrder.instantOrder' | translate }} </h3>  
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="col-12 popup x p-1">    

                            <div class="instant-order ">
                            
                                <div class="row justify-content-around p-2">
                                    <div class="col-md-12 col-12 text-right">
                                     
                                        <form class="order" [formGroup]='form' (ngSubmit)="submit()">
                                          
                                            <div class="form-group my-3">
                                                <select class="form-control" (change)="onChangeCarMaker($event)"  formControlName="brand_id" [ngClass]="{ 'is-invalid': submitted && form.value.brand_id == '0'}">
                                                    <option [value]="0">{{'placeholders.carBrand' | translate }}</option>
                                                    <option *ngFor="let brand of brands" [value]="brand.id" >{{brand.name}}</option>  
                                                </select>
                                                <span class="text-danger mb-3" *ngIf="submitted && form.value.brand_id == '0'"> *{{'main.required' | translate }} </span>
                                            </div>
 
                                        
                                            <div class="form-group my-3">
                                                <textarea class="form-control" id="note" rows="3" placeholder="{{'placeholders.yourQuestion' | translate }}" formControlName='question' [ngClass]="{ 'is-invalid': submitted && f.question.errors }" ></textarea>
                                                <span class="text-danger mb-3" *ngIf="submitted && f.question.errors"> * {{'main.required' | translate }}</span>
                                            </div>

                                            <div class="row vin">  
                                                <div class="col-md-11 col-10 mt-2 pb-1">
                                                    <!-- [ngClass]="{ 'is-invalid': submitted && f.VIN.errors }"-->
                                                    <input type="text"
                                                    placeholder="{{'placeholders.VIN' | translate }}"
                                                    aria-label="Number"
                                                    matInput
                                                    formControlName="VIN"
                                                    (change)="changeValue($event)"
                                                    [matAutocomplete]="auto">
                                                
                                                    <mat-autocomplete #auto="matAutocomplete"  >
                                                        <mat-option *ngFor="let option of filteredOptions | async " [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete> 
                                                
                                                </div>

                                                <div class="col-md-1 col-2">
                                                    <button type="button" class="btn float-right position-relative" 
                                                    popover=" "
                                                    popoverTitle= "{{'instantOrder.popOverData'| translate}}" placement="top" style="right: -20px;">
                                                    <img src="assets/images/orders/noteWebp.webp"  class="image-fluid" height="20px" width="20px" > 
                                                    </button>
                                                </div> 
                                            
                                            </div>     
                                            <span class="text-danger mb-3" *ngIf="submitted && f.VIN.errors">*{{'instantOrder.instantVINValidation' | translate }}</span> 
                                        <!-- </div>   -->
                    
                                            <div class="form-group my-3" >
                                                <input type="text" class="form-control" id="name" placeholder="{{'placeholders.name' | translate }}" formControlName='name' [ngClass]="{ 'is-invalid': submitted && f.name.errors }" [readonly]="toggleExp || user?.data?.user?.phone">
                                                <span class="text-danger mb-3" *ngIf="submitted && f.name.errors">*{{'main.required' | translate }}</span>   
                                            </div>

                                            
                                            <div class="form-group mb-3" *ngIf="!user?.data?.user?.phone">
                                                <div class="input-group"> 
                                                    <input type="text" class="form-control" placeholder="{{'placeholders.phoneNumber' | translate }}" formControlName='phone' [ngClass]="{ 'is-invalid': submitted && f.phone.errors}" aria-describedby="basic-addon2" [readonly]="toggleExp ">
                                                    <div class="input-group-append">
                                                      <button class="input-group-text" id="basic-addon2" type="button" (click)="verify()" [disabled]="toggleExp">{{'main.verify'|translate}}</button>
                                                    </div>
                                                </div>
                                               <span class="text-danger mb-3" *ngIf="submitted && f.phone.errors">*{{'instantOrder.phoneValid' | translate }}</span>   
                                            </div>
                                            
                                            <div class="mt-5" > 
                                            <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false"  [expanded]="confirm"  *ngIf="sendcode && ! user?.data?.user"> 
                                                <mat-expansion-panel-header>
                                                    <mat-panel-description>
                                                        <h3 class="text-light fw-bold"> {{'auth.verificationCode' | translate }} </h3>  
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                        
                                                   <!-- Verify-->
                                                                     
                                                   <form class="mt-5">
                                                    <div class=" row text-center ">
                                                         <div class="col-md-12 verify">
                                                             <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}" width="65px" style="direction: ltr;"></ng-otp-input>
                                                          </div>
                                                    </div>
                                                    <div class="form-group text-center mt-5">
                                                        <button class="btn btn-dark-blue px-3 py-2" (click)="confirmOTP()">{{'main.verify' | translate }}</button>
                                                    </div>
                                                    <div class="text-center mt-4 mb-5">
                                                        <p *ngIf="counter.current > 0"  class="text-secondary  mb-4" class="text-bluetitle">
                                                          {{counter.current}} {{'main.secs' | translate }}
                                                          <br>
                                                        </p>
                                                        <a  *ngIf="counter.current == 0" (click)="resendOTPCode(form.value.phone)" aria-label="forget_password" href="javascript:void(0)"  class="animate__animated animate__heartBeat d-block mt-4 text-bluetitle text-decoration-none" >
                                                          <p class="text-muted resend"> {{'auth.receiveCode' | translate }}<span class="text-bluetitle"> {{'auth.resend' | translate }}</span></p>
                                                        </a>
                                    
                                                       </div>
                                    
                                                </form> 
                                                 <!-- Verify-->
                                                <!-- <div class="form-group text-center ">
                                                    <button class="btn btn-dark-blue p-3 col-8" type="submit"> {{'main.submit' | translate }} </button>
                                                </div> -->
                                                
                                            </mat-expansion-panel> 

                                            <!-- {{confirm}}  {{toggleExp}} -->
                                            <div class="form-group text-center my-4" *ngIf="user?.data?.user"> <!--sendcode || -->
                                                <button class="btn btn-success p-3 col-8" type="submit">  {{'main.submit' | translate }} </button>  <!--[disabled]="confirm==true && form.invalid==true"-->
                                            </div>

                                            <div class="form-group text-center my-4" *ngIf="!user?.data?.user">
                                                <button class="btn  p-3 col-8" type="submit" [disabled]="!toggleExp" [ngClass]="{'btn-success': toggleExp , 'btn-secondary': !toggleExp}">  {{'main.submit' | translate }} </button>  <!--[disabled]="confirm==true && form.invalid==true"-->
                                            </div>
                                           </div>
                                        </form>
                     
                                    </div>
                                </div>
                            </div>

                        </div>
                    
                    </mat-expansion-panel>
               
                </mat-accordion>
            </div>
       
       </div> 
 
     
</section>

 
 