import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay, map } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  footerobj:any = null;
  social_links:any=[];
  public makers:any=[];
  public categories:any=[];
  public models:any=[] ;
  replacement:boolean =true ;
  performance:boolean =true ;
  accessories:boolean =true ;
  about:boolean=true ;
  policies:boolean =true ;
  right :any  ;
  left :any ;
  obj:any={}
  constructor(private service:AppService ,private router:Router) {}

  ngOnInit(): void {
    try {
      this.right= document.getElementById("right-col")
      this.left= document.getElementById("left-col")

       if (window.screen.width<= 425) {
           this.right.classList.add("order-last");
           this.left.classList.add("order-first");
       }
       else {
         this.right.classList.remove("order-last");
         this.left.classList.remove("order-first");
       }
    } catch (error) {
    }

    this.footer();
    this.getCategories() ;
    this.getCarMakers();
    this.getModels();
  }

  goProducts(val:any , type){
  let product_url=type
  let form= {
    brand_ids: null ,
    model_ids:  null ,
    category_ids: null,
    trader_ids:null,
    manufacture_date_id: null ,
    VIN:  null
   }
  switch (type){
    case "category":
    form.category_ids = [val.id]
    this.obj={'category':val.name_en}
    this.service.setRefresh(this.obj)
    localStorage.setItem('filter',JSON.stringify(this.obj) )

    break;
    case "brand":
    form.brand_ids = [val.id]
    localStorage.setItem('brand-filter', val.id)
    this.obj = {'brand':val.name_en}
    this.service.setRefresh(this.obj )
    localStorage.setItem('filter',JSON.stringify(this.obj ) )

    break;
    case "model":
    form.model_ids=[val.id]
    // let mod:any ={}; 
     this.makers.forEach((maker:any) => {
     if(maker.id == val.brand_id){
        this.obj = {'brand':maker.name_en , 'model':val.name_en }
        this.service.setRefresh(this.obj )
        localStorage.setItem('filter',JSON.stringify(this.obj ) )
      }
    //   this.service.setRefresh(this.obj )
    //  localStorage.setItem('filter',JSON.stringify(this.obj ) )
    });
    console.log("obj",this.obj )
    localStorage.setItem('brand-filter', val.brand_id)
    break;
  }
  this.service.setRefresh2(val.id)
  let product_link=val.name_en
  this.router.navigate(['/filter',product_url,product_link])
 
  this.router.routeReuseStrategy.shouldReuseRoute = function () {
    return false;
  };

  window.scrollTo(0, 0);
  }

  footer(){
    this.service.getFooter().subscribe((res:any)=>{
      this.footerobj=res['data']
    //  console.log("footer",this.footerobj)
      this.social_links=this.footerobj?.social_media_items
    })
  }

  getCarMakers(){
    this.service.brandsService.subscribe((brands:any) =>{
      this.makers=brands;
    })
  }

  getCategories(){
    this.service.categoriesService.subscribe((categories:any) =>{
      this.categories=categories;
    })
  }

  getModels(){
     this.service.allModelService.pipe(map(response=>response['data'])).subscribe((models:any) =>{
      this.models=models;
      // console.log("models",this.models)
    })
  }

}
