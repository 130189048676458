import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import { MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { MatChipsModule} from '@angular/material/chips';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatExpansionModule} from '@angular/material/expansion';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppService } from './services/app.service';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { AddAddressComponent } from './shared/add-address-dialog/add-address.component';
import { EditAddressComponent } from './shared/edit-address-dialog/edit-address.component';
import { SharedModule } from './shared/shared.module';
import { DialogComponent } from './shared/evaluate-dialog/dialog.component';
import { InstantOrderComponent } from './shared/instant-order-dialog/instant-order.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MobileLayoutComponent } from './layouts/mobile-layout/mobile-layout.component';
import { BottomBarComponent } from './shared/bottom-bar/bottom-bar.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll'; 
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
 
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    AuthLayoutComponent,
    MobileLayoutComponent,
    BottomBarComponent,
   
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MatDialogModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    NgxSliderModule,
    AccordionModule,
    CarouselModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      }
    }),
    AccordionModule.forRoot(),
    LazyLoadImageModule,

    ToastrModule.forRoot({
      timeOut: 5000,
      easing: 'ease-in',
      progressBar:true,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      tapToDismiss:true
    }),

  ],
  // CookieService,
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AuthGuard  ,
    BsModalRef ,
    BsDropdownConfig,
    BsModalService,
    AppService,
    InfiniteScrollModule,
    HttpClientModule,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ],
  entryComponents: [InstantOrderComponent,DialogComponent,AddAddressComponent,EditAddressComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
function provideAnimations(): import("@angular/core").Provider {
  throw new Error('Function not implemented.');
}

