import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  
   constructor(private router: Router) { }

   canActivate() {
	   if (localStorage.getItem(`${environment.currentUser}`)) {
           return true;
	   }	   
	   this.router.navigate(['/auth/login']);
	   return false;
	}
}
