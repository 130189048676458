import { DOCUMENT } from '@angular/common';
import { Component, Inject  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {  filter, map } from 'rxjs/operators';
import { AppService } from './services/app.service';
import { CartService } from './services/cart.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { InstantOrderComponent } from './shared/instant-order-dialog/instant-order.component';
import { MatDialog } from '@angular/material/dialog';
  
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public cartOpen = false;
  mobile:boolean=false; 
  constructor(public dialog: MatDialog, @Inject(DOCUMENT) private document: Document,private router: Router, private service:AppService , private cart:CartService ,private modalService: BsModalService,) {
    // document.cookie = "username=John Doe";
    // document.cookie = "userphone=01011897810";
    
    // if( window.matchMedia('(max-width: 768px)').matches) {
    //   this.mobile=true;
    //   console.log("mobileee", this.mobile)
    //   this.router.navigate(['/mobile'])
    // }else {
    //   this.mobile=false;
    // }
      // localStorage.removeItem('filter')
    if(!localStorage.getItem("lang")){
      localStorage.setItem("lang","ar")
    }
   
     router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });


        if(  this.router.url.toString()=='/') {
          localStorage.removeItem('filter') 
          localStorage.removeItem('brand-filter')
        }
    })

    this.cart.watchStorage().subscribe((data) => {
       this.cartOpen = !this.cartOpen
    })
    // this.service.setRefresh(true)
  }
  
  ngOnInit() {
    this.mainHome()
       //  this.allCategories() ;
      //  this.allMakers();
     //  this.getCarYear();
    //  this.getBestSeller();
   // this.allModelsOfSpecificMaker([12])
  }

  mainHome(){
    this.service.Allhome().subscribe((res:any)=>{
    //  console.log("mainHome",res)
    //  console.log("res.data.home_reviews",res.data.home_reviews)
      this.service.categoriesService.next(res.data.categories);
      this.service.brandsService.next(res.data.brands);
      this.service.yearsService.next(res.data.manufacture_dates);
      this.service.bestProductsService.next(res.data.best_products)
      this.service.reviewsService.next(res.data.home_reviews)
      this.service.bannersService.next(res.data.banners)
       
     
      // footer
      let allBrands= res.data.brands.map(s=>s.id);
      this.service.allModlesOrProductsByBrandId(allBrands).subscribe((models:any)=>{
        this.service.allModelService.next(models);
      })
    })
  }
  openInstantOrder() {
    // if(this.user){
       const dialogRef = this.dialog.open(InstantOrderComponent, {
         width: '600px',
       
       });
       
       //  dialogRef.afterClosed().subscribe((result:any) => {
       // });
   //  }
   // else {
    // this.router.navigate(['/auth/login'])
   // }
 
   }
  
//all Categories
  // allCategories(){
  //   this.service.allMainCategories().pipe(map(response=>response['data'])).subscribe((categoriesResponse:any)=>{
  //     this.service.categoriesService.next(categoriesResponse);
  //   })
  // }

//all brands
//  allMakers(){
//     this.service.allBrands().pipe(map(response=>response['data'])).subscribe((brandsResponse:any)=>{
//     this.service.brandsService.next(brandsResponse)  ;
//     let allBrands= brandsResponse.map(s=>s.id);
//       this.service.allModlesOrProductsByBrandId(allBrands).subscribe((models:any)=>{
//         this.service.allModelService.next(models);
//       })
//     }) 
//  }

//all models
//  allModelsOfSpecificMaker(brand_ids){
//   this.service.allModlesOrProductsByBrandId(brand_ids).subscribe((models:any)=>{
//     this.service.modelService.next(models);
//     console.log("all models of specific brand",models)
//   })
//  }

 //all year
//  getCarYear(){
//   this.service.allManufactureDates().pipe(map(response=>response['data'])).subscribe((years:any)=>{
//     console.log("all car years", years)
//     this.service.yearsService.next(years);
//   }) 
//  }
 
  // send(){
  //   var opject = {
  //     client_id: "client",
  //     identifier: "201288189340",
  //     multiTimeUse: false,
  //     type: "SMS"
  //   }
  //   this.http.post(`https://sagateway.careem-engineering.com/identity/client/otp`,opject).subscribe(response=>{
  //     console.log(response)
  //   })
// }
  
  
}

