export const environment = {
  production: false,
  currency:'SAR',
  appName:'Scar',
  currentUser:'scar-website', // user key in local storage
  urlPoint:'https://backend-production.ogetgroup.com/public/api',
  mapboxToken:'pk.eyJ1IjoibWVsc2F3eTE5OTYiLCJhIjoiY2swbWZwbTBwMTM1ajNscHBkcmc2Y205aCJ9.9nO6EaSHyiNaTB8UyPpEoA' 
};


//urlPoint:'https://backend-production.scarksa.com/public/api', 
 
// urlPoint:'https://backend-test.scarksa.com/public/api',   

// mapboxToken:'pk.eyJ1IjoiYmFzZW0xMjEyMTk5NCIsImEiOiJja3g1dTJrYnQxYXB6MzBvMWdrcjd5MXFmIn0.YtJZRMq7vvX4T3PGiHj70Q'