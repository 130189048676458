import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'auth-app-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  user;
  constructor(private authService:AuthenticationService) {
    this.authService.currentUser.subscribe(currentUser=> this.user = currentUser)
   }

  ngOnInit(): void {
  }

}
