import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
   public userToken :any ;
    constructor(private spinner: NgxSpinnerService,private router: Router,private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(`${environment.currentUser}`)));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get currentUserValue(): any {
        if(this.currentUserSubject.value != null) { return this.currentUserSubject.value }        
    }

    // login(data): Observable<any> {
    //     const formData: FormData = new FormData();
    //     formData.append("name", data.name);
    //     return this.http.post<any>(`${environment.endPoint}/login`, formData)
    //         .pipe(map(user => {
    //             switch (user.status) {
    //                 case 500:
    //                     user.errors.forEach(error => {
    //                         // this.toastr.error(error,'',{
    //                         //     positionClass: 'toast-bottom-right'
    //                         // });
    //                     });
    //                     this.spinner.hide()
    //                     break;
                
    //                 default:
    //                     this.router.navigate(["/client/departments"])
    //                     window.location.reload()
    //                     this.spinner.hide()
                        // if (user && user.token) {
                        //     localStorage.setItem('currentUser', JSON.stringify(user));
                        //     this.currentUserSubject.next(user);
                        //     this.router.navigate(["/client/departments"])
                        // }
    //                     return user;
    //                     break;
    //             }
             
                
    //         }));
    // }

    login(phone:any){
        const formData: FormData = new FormData();
        formData.append("phone",phone);
       
        // for (const [key, value] of Object.entries(form)) {
        //     if(value!=null) { 
        //         formData.append(key,`${value}`)
        //     }
        // }
        new Response(formData).text().then(console.log)
        return this.http.post(`${environment.urlPoint}/user/login`, formData) 
        // .pipe( map((user:any) => {
        //    if (user  && user.data?.access_token && user.data.is_verified==true ) {
        //        localStorage.setItem(`${environment.currentUser}`, JSON.stringify(user));
        //         this.currentUserSubject.next(user);
        //     } return user;
        // })

        .pipe( map((user:any) => {
            if (user  && user.data?.access_token) {
                localStorage.setItem(`${environment.currentUser}`, JSON.stringify(user));
                 this.currentUserSubject.next(user);
             } return user;
         })
       );
    }

    register(form){
        const formData: FormData = new FormData();
        
        for (const [key, value] of Object.entries(form)) {
            if(value!=null || value!='') { 
                formData.append(key,`${value}`)
            }
        }
        new Response(formData).text().then(console.log)
        return this.http.post(`${environment.urlPoint}/user/register`, formData)
        .pipe( map((user:any) => {
           if (user && user.data?.access_token) {
                this.userToken=user ;
               localStorage.setItem(`${environment.currentUser}`, JSON.stringify(user));
             this.currentUserSubject.next(user);
            } 
           return user;
          })
       );
    }
   
    // forgetPassword(form){
    // is_login
    sendSMSCode(form){
        const formData: FormData = new FormData();
        // if(form.reset_password==1){  //forgetpassword
        //     formData.append("reset_password",form.reset_password);  
        //     formData.append("email", form.email );
        // }
        // else {
        //     formData.append("email", form.email );
        // }
         
        //  new Response(formData).text().then(console.log)
        for (const [key, value] of Object.entries(form)) {
            if(value!=null || value!='') {
              formData.append(key,`${value}`)
            }
          }
        return this.http.post(`${environment.urlPoint}/sms/send`, formData)
    }

    resetPassword(form){
        const formData: FormData = new FormData();
        formData.append("phone",form.phone);  
        formData.append("new_password", form.password );  
        formData.append("confirm_new_password", form.confirm );
         new Response(formData).text().then(console.log)
        return this.http.post(`${environment.urlPoint}/user/password/forget`, formData)
    }
     
    sendSMS(form){
    const formData: FormData = new FormData();
    formData.append("phone",form.phone);  
    formData.append("confirm_code", form.confirm_code);  
        // new Response(formData).text().then(console.log)
      return this.http.post(`${environment.urlPoint}/sms/confirm`, formData)
    
    }

    changePassword(form){
        const formData: FormData = new FormData();
        formData.append("old_password",form.old_password);  
        formData.append("new_password", form.new_password );  
        formData.append("confirm_new_password", form.confirm_new_password );
         new Response(formData).text().then(console.log)
        return this.http.post(`${environment.urlPoint}/user/update/password`, formData)
    }

    logout() {
        localStorage.removeItem(`${environment.currentUser}`);
        this.currentUserSubject.next(null);
        this.router.navigate(['/'])
    }
}