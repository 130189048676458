import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CartComponent } from './cart/cart.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from './pipes/filter-pipe';
import { DialogComponent } from './evaluate-dialog/dialog.component';
import { NgxStarsModule } from 'ngx-stars';
import { MatDialogModule} from '@angular/material/dialog';
import { InstantOrderComponent } from './instant-order-dialog/instant-order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule} from 'ngx-bootstrap/popover';
import { AddAddressComponent } from './add-address-dialog/add-address.component';
import { EditAddressComponent } from './edit-address-dialog/edit-address.component';
import { SearchPartsComponent } from './search-parts/search-parts.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MainBarComponent } from './main-bar/main-bar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MathPipe } from '../components/pipe/math.pipe';
import { ScrollableNavbarComponent } from './scrollable-navbar/scrollable-navbar.component';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
         
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
// import {LazyLoadImageModule} from 'ng-lazyload-image';
import {MatExpansionModule} from '@angular/material/expansion';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgOtpInputModule } from  'ng-otp-input';
import {MatSnackBarModule} from '@angular/material/snack-bar'; 
import {MatSelectModule} from '@angular/material/select';
 
@NgModule({
  declarations: [ MobileNavComponent , ScrollableNavbarComponent,MathPipe ,MainBarComponent,SearchPartsComponent,EditAddressComponent,AddAddressComponent,InstantOrderComponent,DialogComponent,HeaderComponent, TopBarComponent, FooterComponent,CartComponent,FilterPipe ],
  imports:[
    CommonModule,
    RouterModule,
    BsDropdownModule,
    TranslateModule,
    MatDialogModule,
    NgxStarsModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    NgxSpinnerModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatExpansionModule,
    CarouselModule,
    NgOtpInputModule,
    MatSnackBarModule,
    MatSelectModule
    // LazyLoadImageModule
  ],
  exports: [ MobileNavComponent, ScrollableNavbarComponent, MathPipe,MainBarComponent,SearchPartsComponent,EditAddressComponent,AddAddressComponent,InstantOrderComponent,DialogComponent, HeaderComponent, TopBarComponent,FooterComponent,CartComponent,FilterPipe],
})
export class SharedModule { }
