<div id="cart" class="animate__animated animate__slideInRight">
    <div class="content animate__animated animate__slideInRight -1s" >
        <h5 class="fw-bold mb-5 p-3 text-default">{{ 'cart.shoppingCart' | translate }} <img (click)="hideCart()" class="close pointer" src="assets/images/top-bar/Icon_material-close.svg"></h5>
        
        
         <div class="container" *ngIf="allcarts?.length!=0"> 
            <ul class="list-unstyled m-0 p-0 ">
              <li class="row justify-content-between" *ngFor="let cart of allcarts">
                  <div class="col-md-3 col-10 image  align-self-center pl-0" >
                      <img class="image-fluid " src="{{cart?.product.imagePath}}"   height="auto" onerror="this.src='assets/images/products/no-photo.png'" >
                  </div>
      
                  <div class="col-md-9 col-12" >
                      <div class="row mt-2"> 
                        <h6 class="col-8 name fw-bold text-default text-left" *ngIf="cart?.product.name.length>=17">...{{cart?.product.name|slice:0:17}}</h6>
                        <h6 class="col-8 name fw-bold text-default text-left" *ngIf="cart?.product.name.length<17">{{cart?.product.name}}</h6>
                        <div class="col-4 d-flex justify-content-end text-right" >
                          <img class="pointer mx-2 " src="../../../assets/images/cart/trash.svg" (click)="deleteProduct(cart.id)"  height="25px" width="25px">
                        </div>
                      </div> 

                      <div #webDesc class="desc col-12 " id="desc">
                          <p class="text-secondary fw-bold" *ngIf="cart?.product.description.length>=20" > ...{{cart?.product.description|slice:0:20}}</p>
                          <p class="text-secondary fw-bold" *ngIf="cart?.product.description.length<20" > {{cart?.product.description}}</p>
                        
                      </div>  

                      <div class="row "> 
                          <div class="mt-2 count col-md-6 col-12 justify-content-start d-flex ">
                            <button class="position-relative left-0 fw-bold" (click)="update(cart.product.id,cart.quantity,false)" [disabled]="cart.quantity==1">-</button>
                            <input type="number" value={{cart?.quantity}} disabled>
                            <button class="position-relative right-0 fw-bold" (click)="update(cart.product.id,cart.quantity,true)">+</button>
                          </div>

                          <div class="price mt-2 col-md-6 col-12"> 
                            <span class="fw-bold text-bluetitle "> {{cart?.product.price_after_discount}} {{ 'cart.sar' | translate }} </span>  &nbsp;
                            <del class="text-secondary fw-bold "  *ngIf="cart?.product.price_after_discount!=cart?.product.price">  {{cart?.product.price}} {{ 'cart.sar' | translate }} </del> 
                          </div>
                      </div>
                  </div>
              </li>
            </ul>
         </div>
         
         <div class="container no-image" *ngIf="allcarts?.length==0">  
          <div class="text-center no-products p-3">
                  <img class="image-fluid" src="../../../assets/images/top-bar/no-cart-image.png" height="auto" width="75%">
                  <div> 
                      <span class="fw-bold d-block my-2">{{ 'cart.emptyCart' | translate }}</span>  
                      <span class="pt-2 text-secondary">{{ 'cart.emptyCartParagraph' | translate }}</span> <br>
                      <span class="text-secondary">{{ 'cart.emptyCartExplore' | translate }} </span>
                       
                  </div>
                  <button (click)="hideCart()" class="btn btn-success mt-4 mr-5" [routerLink]="['/']">{{ 'orders.shopOurProducts' | translate }}</button>
            </div> 
         </div>
         <!-- <div class="container" *ngIf="allcarts?.length==0">
           <div *ngFor="let car of [].constructor(2)">
            <div class="ph-item">
              <div>
                    <div class="ph-row">
                        <div class="ph-col-12 empty"></div>
                        
                        <div class="ph-col-4 empty"></div>
                        <div class="ph-col-8"></div>
  
                        <div class="ph-col-8 empty"></div>
                        <div class="ph-col-4"></div>

                        <div class="ph-col-8 empty"></div>
                        <div class="ph-col-4"></div>

                        <div class="ph-col-6 empty"></div>
                        <div class="ph-col-6"></div>
                    </div>
                </div>
                <div class="ph-col-2">
                  <div class="ph-avatar"></div>
              </div>
            </div>
          </div>
         </div> -->

        <div class="showPriceTable col-md-12 "> 
            <table class="table table-borderless">
                  <tbody>
                      <tr>
                        <th scope="row" class="text-secondary">{{'placeholders.subTotal' | translate }}</th>
                        <td class="text-right">{{subtotal_price}} {{ 'cart.sar' | translate }}</td>
                        <!-- <td class="text-right">{{cart_info[0]}} {{ 'cart.sar' | translate }}</td> -->
                      </tr>
                      <tr>
                        <th scope="row" class="text-secondary">{{'placeholders.shippingFees' | translate }}</th>
                        <td class="text-right">{{shipping}} {{ 'cart.sar' | translate }}</td>
                        <!-- <td class="text-right">{{cart_info[1]}} {{ 'cart.sar' | translate }}</td> -->
                      </tr>
                      <tr>
                        <th scope="row" class="text-secondary">{{'placeholders.tax' | translate }}</th>
                       <td class="text-right">{{total_tax?.toFixed(2) }} {{ 'cart.sar' | translate }}</td>    
                       <!-- <td class="text-right">{{cart_info[2]?.toFixed(2) }} {{ 'cart.sar' | translate }}</td> -->
                      </tr>
                      <tr class="total">
                        <th class="text-default"  scope="row"><b>{{'placeholders.total' | translate }}</b></th>
                        <td class="text-bluetitle text-right"><b>{{total_price}} {{ 'cart.sar' | translate }}</b></td>
                        <!-- <td class="text-bluetitle text-right"><b>{{cart_info[3]}} {{ 'cart.sar' | translate }}</b></td> -->
                      </tr>
                  </tbody>
                </table> 
                <div class="text-center mt-2 pb-2">
                  <button (click)="goToCheckout()" class="btn btn-success pl-4 pr-4">{{'orders.procceedToCheckout' | translate }}</button>
              </div>
        </div>
        
    </div>
</div>
 