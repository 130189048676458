import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public someProp  = new BehaviorSubject<string>(null);
  public storageSub= new Subject<boolean>();
  user;

  shrouk = [
    {id:1 , phone:"01011897811"},
    {id:2 , phone:"01011897822"},
  ]

  watchStorage(): Observable<any> { return this.storageSub.asObservable() }

  constructor(private http: HttpClient,private auth:AuthenticationService) {
    this.auth.currentUser.subscribe(currentUser=>this.user=currentUser)
   }

   getInfo():Observable<any[]>{
    //console.log("run")
    return of(this.shrouk)
   }



/////////////////////////
  getCarts(){
    return this.http.post(`${environment.urlPoint}/cart`,this.user.token)
  }

///////////////shrouk//////////////////////

  updatCart(f){
   const formData:FormData = new FormData();
   for (const [key, value] of Object.entries(f)) {
    if( (value!=null)|| (value!="null")) {
      formData.append(key,`${value}`)
    }}
    return this.http.post(`${environment.urlPoint}/cart/update`,formData)
  }

  addTocart(f){

   const formData:FormData = new FormData();

   for (const [key, value] of Object.entries(f)) {
     if( value!="null"  && value!=null) {
       formData.append(key,`${value}`)
     }}
   return this.http.post(`${environment.urlPoint}/cart/create`,formData)
  }

  cartShow(session_id){

    if(session_id!=null){
      const formData:FormData = new FormData();
      formData.append('session_id',session_id)

      return this.http.post(`${environment.urlPoint}/cart/show`,formData)
    }
    else {
     // console.log("cart null" )
      return this.http.post(`${environment.urlPoint}/cart/show`,'null')
    }

  }


  getTax_Shipping(){
    return this.http.get(`${environment.urlPoint}/get/options`)
  }

  deleteProduct(f){
   const formData:FormData = new FormData();
    formData.append('cart_item_id',f.cart_item_id)
    formData.append('session_id',f.session_id)
    return this.http.post(`${environment.urlPoint}/cart-item/delete`,formData)
  }

 checkOutCoupons(){
  return this.http.get(`${environment.urlPoint}/coupons/all`)
 }

}
