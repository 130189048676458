import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  map, startWith, take } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable, interval } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
  import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-instant-order',
  templateUrl: './instant-order.component.html',
  styleUrls: ['./instant-order.component.scss']
})
export class InstantOrderComponent implements OnInit {  //, AfterViewInit 
 horizontalPosition: MatSnackBarHorizontalPosition = 'right';
 verticalPosition: MatSnackBarVerticalPosition = 'bottom';
 submitted:boolean=false
 verified:boolean=false
 brands:any=[];
 form:FormGroup;
 brand__id ;
 user:any
 response_case:boolean = false; 
 public options:string[]= []
 filteredOptions: Observable<string[]>;
 panelOpenState = false;
 panelOpenState2=false
 sendcode:boolean=false ;
 confirm:boolean=true ;
 toggleExp:boolean=false ;
 durationInSeconds = 5;
//  token:any=JSON.parse(localStorage.getItem('scar-website'))
 expanded:boolean=false ;

 public OTP : any = {
  length: 4,
  code: '',
};
public counter : any = {
  highest: 23,
  current: 23
};
 images:any = [
               {image:'assets/images/orders/personal-data-pana.svg', title:this.translate.instant('instantOrder.carousel_title1') ,description: this.translate.instant('instantOrder.carousel_text1')},
               {image:'assets/images/orders/call.svg', title:this.translate.instant('instantOrder.carousel_title2'),description: this.translate.instant('instantOrder.carousel_text2')},
               {image:'assets/images/orders/take-away-pana.svg' , title:this.translate.instant('instantOrder.carousel_title3'),description: this.translate.instant('instantOrder.carousel_text3')}
              ]
 
  customOptions : OwlOptions= {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    merge:true,
    pullDrag: true,
    dots: true,
    autoWidth:false,
    navSpeed: 600,
    center: true,
    autoplay: true,
    slideTransition: "ease-in-out",
    smartSpeed:1000,
    nav: false,
    animateOut: 'fadeOut',
 
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 1
    },
    940: {
      items: 1
    }
  },
}
 constructor(private authService:AuthenticationService,
  private translate:TranslateService,
  private authenticationService:AuthenticationService,
  private router:Router,
  private service:AppService ,
  private formbuilder:FormBuilder ,
  public dialogRef: MatDialogRef<InstantOrderComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any ,
  private renderer:Renderer2 ,
  private el:ElementRef ,
  private snackBar: MatSnackBar,
  private toast:ToastrService
  ) { 
  this.authenticationService.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
    // console.log("user",this.user.data.user )
   if(JSON.parse(localStorage.getItem('vin')) !=null){
    this.options=JSON.parse(localStorage.getItem('vin'))
    console.log("options",this.options)
   }
    //console.log("el" ,this.el.nativeElement)
    // this.renderer.setStyle(this.el.nativeElement,'min-height', '421px')
    // this.renderer.setStyle(this.el.nativeElement,'overflow', 'auto')
 }
 

  openSnackBar(message: string, action: string) {
    if(action=='success') {
      this.snackBar.open(message, action , {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'notif-success',
        duration: 4000,
      });
    
    }
    else {
      this.snackBar.open(message, action , {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass:'notif-fail',
        // duration: 4000,
      });
    }
  }

  ngOnInit(): void {
     this.form = this.formbuilder.group({
      name:['',Validators.required],
      VIN:['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9 a-z A-Z]{17}$")]],
      phone:['',Validators.required],  //  [, Validators.pattern("^((\\+91-?)|0)?[0-9]{9}$")]
      question:['',Validators.required],
      brand_id:['0',Validators.required], //12
   })

  //  setTimeout(() => {
  //     document.getElementById('test').click()
  //  }, 500);
  //  
  // ngAfterViewInit(){
  //   alert()
  // }

   this.filteredOptions = this.form.controls.VIN.valueChanges.pipe(
    startWith(''),
    map((value:any) => this._filter(value || '')),
   );

   if(this.user!=null) {
    this.form.patchValue({
      name:this.user?.data?.user?.full_name ,
      phone:this.user?.data?.user?.phone
    })
    this.sendcode=true
   }
    console.log("dialog",this.user)
    this.getBrands();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option:any) => option.toLowerCase().includes(filterValue));
  }
 
  getBrands(){
    this.service.allBrands().pipe(map(response=>response['data'])).subscribe((response:any)=>{
      this.brands = response;
    })
  }

  changeExpansion() {
   this.expanded=!this.expanded
  }

  onChangeCarMaker(e:any){
    this.brand__id=e.target.value
   // console.log("brand id" , this.brand__id)
  }

  changeValue(event:any) {
   console.log("event" ,event.target.value)
   if(event.target.value !="") {
    this.options.push(event.target.value)
    localStorage.setItem('vin',JSON.stringify(this.options))
   }
   
  }

  verify(){
    console.log("verify",this.form.value.phone)
    this.resendOTPCode(this.form.value.phone) 
  }

  get f() {
    return this.form.controls;
  } 

  resendOTPCode(phone){
   this.submitted=true ;
   let otpform={
     phone:phone,
    //  is_login:null
   }
   this.countDown(this.counter.highest)
   console.log("valid",this.f.phone.valid)
   if(this.form.invalid) {
    this.sendcode=false ; 
    return ; 
   }
   else {
    this.sendcode=true ; 
    console.log(this.sendcode)
   }
  
   this.authService.sendSMSCode(otpform).subscribe((res:any)=>{
        console.log("OTP" ,res)
        if(res.status==true) {
          this.toast.success(res.message,'Send Code')
        }
        else {
          this.toast.success(res.message,'Send Code')
        }
   })
  }
  
  confirmOTP(){
    console.log("token",this.user)
    let authform = {
       phone:this.form.value.phone , 
       confirm_code : this.OTP.code ,
    }
    this.authService.sendSMS(authform).subscribe((response:any)=>{ 
      console.log("confirm" , response)
      if(response.status==true) {

        if(this.form.invalid) {
            this.toggleExp=false ; 
            console.log("toggleExp if",this.toggleExp)
            return ; 
        }
        else {
            this.toggleExp=true ; 
            console.log("toggleExp else",this.toggleExp)
        }
       
        if (response.user_exist==false){
            this.toast.success("user doesn't exist so we will create accout to you",'Success')
              let signupForm= {
                phone: this.form.value.phone ,
                name: this.form.value.name
              }
            this.authService.register(signupForm).subscribe((res:any)=>{ 
            if(res.status==true) {
              this.toast.success(res.status,'Register')
              this.confirm=false
            }
            else {
              this.toast.error(res.errors[0],'Register')
              this.confirm=true
            }
            })
          }
          else {
            this.toggleExp=true
            this.toast.success("user exists so you can enter your account", 'Success')
            this.authService.login(this.form.value.phone).subscribe((res:any)=>{
              if(res.status==true) {
                this.toast.success(res.status,'User Login')
                this.confirm=false
              }
              else {
                this.toast.error(res.errors[0],'User Login')
                this.confirm=true
              }
            })
          }
      }
      else {
        this.toast.error(response.errors[0],"Confirm Code ")
      }
    })
  }

  countDown(duration = this.counter.highest) {
    return interval(1000).pipe(take(duration), map(count => duration - count)).subscribe(timer => {
      this.counter.current = --timer
    })
  }

  onOtpChange(event:any){
    this.OTP.code=event
  }

  submit(){
    this.submitted = true;
    let submitForm = {
      ...this.form.value ,
    }
    //console.log("Submitted Form " ,submitForm )
    if (this.form.invalid) {
      //alert("form invalid")
      return;
    }
    this.service.createInstantOrder(this.form.value).subscribe((res:any)=>{
     if(res.status==true){
        this.response_case =true ;
        this.toast.success(res.message,'Instant Order')
        this.dialogRef.close()
        this.router.navigate(['/orders/all'],{ queryParams: { order: 'instant'}})
      }
      else {
        this.response_case =false ;
        this.toast.error(res.errors[0],'Instant Order')
     }
    },
    (err:any)=>{
         Swal.fire(
          `Fail`,
          `Unauthenticated , Please Enter The Confirm Code First` ,
          `error`
        )
      })
  }
}
