import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InstantOrderComponent } from 'src/app/shared/instant-order-dialog/instant-order.component';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProductsService } from 'src/app/services/products.service';
import {MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import * as url from 'url'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit ,OnChanges{
  public user = null;
  specialists = []
  cat=[]
  categories=[]
  collapsed:boolean=true;
  collaps_btn:boolean=true;
  show_search:boolean=false;
  showheart:boolean=true;
  showcart:boolean=true;
  showsetting:boolean=true;
  showsearch:boolean=true;
  auth:boolean=false;
  hide_search_icon_in_filter_page:boolean = true ;
  @ViewChild('search')search:ElementRef ;
  modalRef?: BsModalRef;
  loadAPI: Promise<any>;
  // selected_lang:any='en'
  langs = [
    { lang: 'en', flag: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-button-round-250.png', name: 'english'},
    { lang: 'ar', flag: 'https://cdn.countryflags.com/thumbs/saudi-arabia/flag-3d-round-250.png', name: 'arabic'}
  ]
  // selectedFlag = this.langs[0].flag
  selectedFlag=this.langs[1].flag
 
  constructor(private modalService: BsModalService,private productsService:ProductsService,private cart:CartService, public dialog: MatDialog ,private router: Router, private route : ActivatedRoute,public service:AppService,public translate: TranslateService,private authenticationService:AuthenticationService) {
    this.authenticationService.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
    let language_storage = localStorage.getItem("lang")
    if(language_storage) {
    
      this.loadAPI = new Promise((resolve) => {
        translate.addLangs(['en', 'ar']);
     //  console.log("language_storage",language_storage)
        translate.setDefaultLang(language_storage);
  
         if(localStorage.getItem("lang")=="ar") {
          this.selectedFlag = this.langs[1].flag
          document.body.setAttribute('lang',"ar")
         }
         else {
          this.selectedFlag = this.langs[0].flag
          document.body.setAttribute('lang',"en")
        
         }
   
      })
    }
    else {
     
      this.loadAPI = new Promise((resolve) => {
        translate.addLangs(['en', 'ar']);
        translate.setDefaultLang('en');
  
         if(localStorage.getItem("lang")=="ar") {
          this.selectedFlag = this.langs[1].flag
          document.body.setAttribute('lang',"ar")
         }
         else {
          this.selectedFlag = this.langs[0].flag
          document.body.setAttribute('lang',"en")
        
         }
      // const browserLang = translate.getBrowserLang();
      //  translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
      })
    }
    
     router.events.subscribe((event: any) => {
    
      if (event instanceof NavigationEnd ) {
       
        const urlInfo = url.parse(this.router.url)
   
        if(this.router.url.split('/')[1]=="products") {
        //  console.log(this.router.url.split('/')[1])
           this.hide_search_icon_in_filter_page=false ;
         }
        else {
           this.hide_search_icon_in_filter_page=true ;
         }

         if(this.router.url.split('/')[1]=="auth") {
          this.auth=true ;
         }
        else {
          this.auth=false ;
         }

        // hide logo
        if(window.matchMedia('(max-width: 500px)').matches && this.router.url.split('/')[1]=="products") {
          this.hide_search_icon_in_filter_page=false ;
        //  console.log(true)
       
        }
        else {
          this.hide_search_icon_in_filter_page=true ;
        //  console.log(false)
        }
      }
    });
  }
 

  ngOnInit():void {
  //small screens ;
 
    let small_screen_Width=window.matchMedia('(max-width: 992px)');
    if(small_screen_Width.matches==true) {
      this.collaps_btn=true; 
    }
    else {
      this.collaps_btn=false; 
    }
 
    let large_screen_Width=window.matchMedia('(min-width: 992px)');
    if(large_screen_Width.matches==true) {
      this.collaps_btn=false; 
    }
    else {
      this.collaps_btn=true; 
    }
  
   // this.getCategories() 
  }

   ngOnChanges(changes: SimpleChanges): void {
  }

  //toogleNav:boolean=false
  doFilter(){
   // this.service.toggleFilter=false;
     this.service.toggleFilter= !this.service.toggleFilter ;
    //this.toogleNav=this.service.toggleFilter ;
   // console.log("toogleNav" , this.toogleNav)
  }
  //change body lang
  changeLang(lang,flag){
    this.selectedFlag = flag
    document.body.setAttribute('lang',lang)
    localStorage.setItem("lang",lang)
  }
 
  logout(){
    this.authenticationService.logout()
    localStorage.removeItem("session_id")
 
  }

  openInstantOrder() {
   // if(this.user){
      const dialogRef = this.dialog.open(InstantOrderComponent, {
        width: '600px',
        //height:'100%'
      });
      
      //  dialogRef.afterClosed().subscribe((result:any) => {
      // });
  //  }
  // else {
   // this.router.navigate(['/auth/login'])
  // }

  }

  toggleCollapsed()  {
    this.collapsed = !this.collapsed;
  }
 
  // getCategories(){
  //   this.service.allMainCategories().pipe(map(response=>response['data'])).subscribe(response=>{
  //     for(let i =0 ; i<3; i++){
  //        this.cat.push(response[i]);
  //      }
  //      this.specialists = this.cat;
  //    //  console.log("main categories",this.specialists)
  // })
  // }

  showCart(){
    this.router.navigate(['/orders/shopping-cart']);
  }
 
  goProducts(category_id){
    this.categories[0]=category_id
    const form = {  
    brand_ids: null ,
    model_ids:  null , 
    category_ids: this.categories || null,
    trader_ids:null,
    manufacture_date_id: null ,
    VIN:  null
    }
    this.service.setRefresh(form)
    // ,{relativeTo: this.route}
  localStorage.setItem("filter",JSON.stringify({...form}))
  this.router.navigate(['/filter']);
  localStorage.setItem("refresh","true")
  }

  public search_new
  setSearchValue(event) {
    this.search_new=event.target.value
    //console.log("event", this.search_new)
  }
  
  // searchProductsModel(){
  //   console.log("event", this.search_new)
  // }

  searchProducts(type){
    //  let search_word = this.search.nativeElement.value ;
    let search_word = this.search_new ;
 //    console.log("search_word" , search_word)
     this.productsService.getSearchProduct(search_word).subscribe((response:any)=>{
   //   console.log("search" , response)
       if(response.status==true){
        const form = {  
          brand_ids: null ,
          model_ids:null , 
          category_ids: null ,
          trader_ids: null ,
          manufacture_date_id: null ,
          product_name:search_word,
         }
           // console.log("Home Form",form)
           localStorage.setItem("filter",JSON.stringify({...form}))
            this.router.navigate(['/products',search_word]); 
           localStorage.setItem("url","category")
            localStorage.setItem('refresh','false')
            this.hide_search_icon_in_filter_page=false
            // if(type==2){
              this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
              };
            // }
           
            // if( localStorage.getItem('refresh')=='true'){
            //   this.hide_search_icon_in_filter_page=true
            // }
            // else {
            //   this.hide_search_icon_in_filter_page=false
            // }
            
       }

     })
  }
   
  closeNav() {
      let small_screen_Width=window.matchMedia('(max-width: 992px)');
      if(small_screen_Width.matches==true) {
       this.collaps_btn=!this.collaps_btn
      }
      else {
      //  console.log(" ")
      }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
}
