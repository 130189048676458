import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
 
export class AppLayoutComponent implements OnInit {
  mobile:boolean=false ;
  constructor() { 
    if( window.matchMedia('(max-width: 768px)').matches) {
      this.mobile=true;
    }else {
      this.mobile=false;
    }
  }

  ngOnInit(): void {
  }

}
