
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthenticationService } from './authentication.service';
import { CartService } from './cart.service';
import { WishlistService } from './wishlist.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PublicfunctionService {
  public cartInfoService: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public cartItemsService: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  session_id:any ;
  constructor(private http: HttpClient,private auth:AuthenticationService , private cartService:CartService , private wishService:WishlistService , private cart:CartService , private router:Router)  {
  }
   
  // status  ==> there is to 2 kind of status based on response
  addItemToCart(product:any,index:any,arr:any , status){
    let inCart , quantity ,form  ;
    const  sessionId = localStorage.getItem("session_id");
    if(status==0){
      inCart = Boolean(product.in_cart),
      quantity = Number(product.in_cart_quantity);
    }else if(status==1){
      inCart = Boolean(product.product.in_cart),
      quantity = Number(product.product.in_cart_quantity);
    }
 
    if(status==0){ 
      form = {
        product_id:product.id,
        quantity:1,
        session_id:sessionId || "null"
      }
    }
    else if(status==1) {
      form = {
        product_id:product.product.id,
        quantity:1,
        session_id:sessionId || "null"
      }
    }
    if(sessionId){
      if(quantity == 0){
        this.cartService.addTocart(form).subscribe((res:any)=>{
          this.cartService.storageSub.next(true);
          if(status==0){
            arr[index].in_cart_quantity = form.quantity
          }
          else if (status==1) {
            arr[index].product.in_cart_quantity = form.quantity
          }
          
        })

      }else{
        form.quantity = quantity +1
        this.cartService.updatCart(form).subscribe((res:any)=>{
      //    console.log("res",res)
          if(status==0){
            arr[index].in_cart_quantity = form.quantity
          }
          else if (status==1){
            arr[index].product.in_cart_quantity = form.quantity
          }
         if(res.data){
            this.cartService.storageSub.next(true);
          }
          if(res.status == false){
            this.cartService.addTocart({...form,quantity :1}).subscribe((res:any)=>{
              if(status==0){
                arr[index].in_cart_quantity = 1
              }
               else {
                arr[index].product.in_cart_quantity = 1
               }
              this.cartService.storageSub.next(true);
            })
          }
        })
      }
    } 
    // if user first login in site
    else{
      this.cartService.addTocart(form).pipe().subscribe((response:any)=>{
        if( response.data?.session_id!=null){
       //   console.log("session", response.data?.session_id)
            localStorage.setItem("session_id", response.data.session_id)
        }
      })
    }
    //  this.cartService.storageSub.next(true);
 } 
 
 addItemToWishList(product_id) {
  let local = localStorage.getItem("session_id");
  let form= {
      product_id : product_id ,
      session_id :local || "null"
      }
    console.log("form",form)
    this.wishService.toggleWishList(form).subscribe((res:any)=>{
    this.session_id= res['data']?.session_id
      if (res['data'] !=null && localStorage.getItem("scar-website")==null){
      localStorage.setItem("session_id" , res['data']?.session_id)
      }
      if(res['data']){
        console.log("Added")
      }
      else {
        console.log("Remove")
       }
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      
  })
    
} 

  // addItemToWishList(product_id) {
  //   let form ;
  //   if (localStorage.getItem("session_id")==null) {
  //     form= {
  //       product_id: product_id 
  //       }
  //   }
  //   else if (localStorage.getItem("session_id")!=null) {
  //     form= {
  //       product_id: product_id , 
  //       session_id : localStorage.getItem("session_id") 
  //     }
  //   }
  
  //   this.wishService.toggleWishList(form).subscribe((res:any)=>{
  //     this.session_id= res['data']?.session_id
  //       if (res['data'] !=null && localStorage.getItem("scar-website")==null){
  //       localStorage.setItem("session_id" , res['data']?.session_id)
  //       }
  //       if(res['data']==null){
  //         console.log("Remove")
  //         // Swal.fire(
  //         //   'Removed',
  //         //   'Removed from wishlist Successfully',
  //         //   'error'
  //         // )
  //       }
  //       else {
  //         console.log("Added")
  //         // Swal.fire(
  //         //   'Added',
  //         //   'Added to wishlist Successfully',
  //         //   'success'
  //         // )
  //       }
  //       this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //         return false;
  //       };
        
  //   })
      
  // } 



  public subtotal_price :number=0 
  public total_price  :number=0  
  public shipping :number=0 
   public tax  :number=0 
   public total_tax  :number=0 
   public carts :any=[];

  getItemsInCart(){
   
    let session_id = localStorage.getItem("session_id")||null ;
    this.cart.cartShow(session_id).pipe(map(res=>res['data'])).subscribe((items:any) => {
      console.log("items",items)
       if(items['cart_items'].length!=0 ){
        items['cart_items'].forEach((cart:any) => {
      
        this.subtotal_price+=Number(cart.price)* Number(cart.quantity)   ;
        this.cart.getTax_Shipping().subscribe((res:any)=>{
           this.shipping=Number(res.data.shipping_fee);
          this.tax=Number(res.data.tax) ;
           this.total_tax=(this.tax/100)*(this.subtotal_price );
          this.total_price= this.total_tax + this.shipping  +   this.subtotal_price 
       
       
        //  this.carts=  [this.subtotal_price ,this.shipping ,this.total_tax ,this.total_price]
          this.cartInfoService.next( [this.subtotal_price ,this.shipping ,this.total_tax ,this.total_price])
 
         });
        
        });
        this.cartItemsService.next(items['cart_items'])
    
       }
     
      else {
        this.cartItemsService.next(null)
      }
 
 
     } );
 
    
  }
}
