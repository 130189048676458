import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  user
  constructor(private http:HttpClient,private auth:AuthenticationService) { 
    this.auth.currentUser.subscribe(currentUser=>this.user=currentUser)
  }
///////////////////////Shrouk/////////////////////////
addAddress(form){
  const formData:FormData = new FormData()
  formData.append("email", form.email );
  formData.append("mobile_number", form.mobile_number);
  formData.append("first_address_line", form.first_address_line);
  formData.append("second_address_line", form.second_address_line);
  formData.append("country", form.country);
  formData.append("city_id", form.city_id);
  formData.append("area", form.area);
  formData.append("region", form.region);
  formData.append("lat", form.lat);
  formData.append("lng", form.lng);
  formData.append("zipcode", form.zipcode);
  return this.http.post(`${environment.urlPoint}/user/address/create`,formData)
}

editAddress(form){
 
  return this.http.post(`${environment.urlPoint}/user/address/update`,form)
}

getAdresses(){
  return this.http.get(`${environment.urlPoint}/user/addresses/all`)
}
 
delete(address_id){
  const formData: FormData = new FormData();
  formData.append("address_id", address_id);
  return this.http.delete(`${environment.urlPoint}/user/address/delete?address_id=${address_id}`)  
}

///////////////////////Shrouk/////////////////////////

/////////////////////Sawy/////////////////////////////
  // add(address,lat,lng,name){
   
  //  // console.log(name)
  //   const formData: FormData = new FormData();
  //   formData.append("address", address);
  //   formData.append("lat", lat);
  //   formData.append("lng", lng);
  //   formData.append("name", name);
  //   return this.http.post(`${environment.endPoint}/account/addresses/add`,formData)
  // }
  // getAll(){
  //   return this.http.post(`${environment.endPoint}/account/addresses`,{})
  // }

  
 /////////////////////Sawy///////////////////////////
}
