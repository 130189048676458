import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {

  constructor(private service:AppService , private formbuilder:FormBuilder ,public dialogRef: MatDialogRef<AddAddressComponent>, @Inject(MAT_DIALOG_DATA) public data: any ,private authenticationService:AuthenticationService) { 
    this.authenticationService.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
  }
  user=null;
  form:FormGroup;
  city:number ; 
  country:number ;
  cities:any=[];
  countries:any=[];
  submitted:boolean =false ;
  ngOnInit(): void {
    this.getAllCities() ;
    this.getAllCountries();

    this.form = this.formbuilder.group({
      first_name:[this.user.data.user.first_name,Validators.required],
      last_name:[this.user.data.user.last_name,Validators.required],
      phone:['',[Validators.pattern("[0-9 ]{9}"),Validators.required]],
      address:['',Validators.required],
      city_id:[1,Validators.required],
      country_id:[1,Validators.required],
    })
  }

  getAllCities(){
    this.service.getCities().subscribe(res=>{
      this.cities=res['data'];
     // console.log("All Cities" , this.cities)
    })
  }

  getAllCountries(){
    this.service.getCountries().subscribe(res=>{
      this.countries=res['data'];
   //   console.log("All Countries" , this.countries)
    })
  }
  
   get f() {return this.form.controls}
  submit() {
    if (this.form.invalid) { return ; }
    this.submitted=true ;
    let newForm ={
     ...this.form.value ,
    }
     this.service.addAddress(newForm).subscribe((response:any)=> {
    //   console.log("Add Address" , response)
       if(response.status==true){
        Swal.fire(
          `Success` , 
          `${response.message}` , 
          `success`
        )
        this.dialogRef.close();
       }
       else {
        Swal.fire(
          `Fail` , 
          `${response.errors[0]}` , 
          `error`
        )
       }
     })
  }
}
